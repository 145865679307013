export const adventures = [
  {
    url: "https://d1puf2nnbcyl9q.cloudfront.net/mongolia_landing.mp4",
    title: "Mongolia madness",
    caption: `TELL ME WHY`,
    link: "https://www.sotravel.com/all-trips?broad=360",
  },
  {
    url: "https://d1puf2nnbcyl9q.cloudfront.net/ski_landing.mp4",
    title: "French Alps > Japan Alps",
    caption: `Sorry guys i don't make the rules.`,
    link: "https://www.sotravel.com/all-trips?broad=snow",
  },
  {
    url: "https://d1puf2nnbcyl9q.cloudfront.net/rinjani_landing.mp4",
    title: "Conquered Rinjani",
    caption: `The mountain that made me fall in love with hiking`,
    link: "https://www.sotravel.com/all-trips?broad=land",
  },
  {
    url: "https://d1puf2nnbcyl9q.cloudfront.net/dive_landing.mp4",
    title: "Got real wet in Thailand",
    caption: `Went really deep too`,
    link: "https://www.sotravel.com/all-trips?broad=dive",
  },
  {
    url: "https://d1puf2nnbcyl9q.cloudfront.net/surf_landing.mp4",
    title: "I am paddle poop",
    caption:
      "Surfing is like my ex - keeps trying to drown me but I keep coming back for more",
    link: "https://www.sotravel.com/all-trips?broad=surf",
  },
];

export const companyReviews = [
  {
    review: "“For Gen Zs & Millennials itching for an unique adventure”",
    imageSrc: "/landing/today-company.png",
  },
  {
    review: "“Expand your social network in an authentic and fun way”",
    imageSrc: "/landing/channel-newsasia.png",
  },
  {
    review: `"A passion project that's shaking up the travel industry”`,
    imageSrc: "/landing/asia-one.png",
  },
  {
    review: `"Probably the most affordable & unique tours in the market”`,
    imageSrc: "/landing/business-insider.png",
  },
];

export const faqs = [
  {
    question: "What is included in a Sotravel package",
    answer: (
      <p>
        <p className="tw-font-bold tw-mb-2">
          Accommodation, activities, transportation, and local guide
        </p>
        <p>
          Our trips are thoughtfully curated to include the best value
          experiences while still giving you the freedom to explore and discover
          on your own. We typically take care of accommodations, must-do
          activities, local transportation and expert guides. For the exact
          inclusion of each trip, you can find them in our trip details.
        </p>
      </p>
    ),
  },
  {
    question: "What is your cancellation policy",
    answer: (
      <p>
        <p className="tw-font-bold tw-mb-2">
          Free cancellation because we know things can change
        </p>
        <p>
          Most of our trips are free to cancel 30 to 60 days before departure.
          Our sweet cancellation policy allows you to reserve your spot far in
          advance and make changes as needed. For the specific cancellation
          policy of each trip, you can find it in the trip's checkout page.
        </p>
      </p>
    ),
  },
  {
    question: "Are flighs and travel insurance included",
    answer: (
      <>
        <p>
          <p className="tw-font-bold tw-mb-2">
            Why it's important to purchase your travel insurance the moment you
            book a trip
          </p>
          <p>
            Personal travel insurance is not included in your package and you
            are highly recommended to purchase it given the adventurous nature
            of our trips! Travel insurance can also protect you financially if
            you need to cancel last minute for reasons like illness or
            emergencies.
          </p>
        </p>{" "}
        <p>
          <p className="tw-font-bold tw-mb-2 tw-mt-2">
            Why flights are not included
          </p>
          <p>
            Flights are not included to give you maximum flexibility. Many of
            our travelers like to extend their adventures with pre or post-trip
            explorations, so booking your own flights allows you to customize
            your travel dates and routing. Don't worry though - we provide
            detailed flight recommendations and arrival/departure guidelines to
            help you plan your journey. This way, you can choose flights that
            best fit your schedule and travel preferences while still ensuring
            you arrive in time for all the fun!
          </p>
        </p>
      </>
    ),
  },
  {
    question: "What is the kind of people that joins a Sotravel trip?",
    answer: (
      <p>
        <p className="tw-font-bold tw-mb-2">
          Adventurous, open-minded travelers
        </p>
        <p>
          Sotravel attracts a diverse group of adventurous, open-minded
          travelers typically aged 25-45. Our travelers come from various
          backgrounds but share a common interest in meaningful travel
          experiences and making new connections. Whether you're a young
          professional, digital nomad, or simply someone looking to explore the
          world with like-minded individuals, you'll find your tribe with us.
        </p>
      </p>
    ),
  },
  {
    question: "Can introverts join a Sotravel trip",
    answer: (
      <p>
        <p className="tw-font-bold tw-mb-2">
          Our founder, Larry, is an introvert
        </p>
        <p>
          Our socials may look a little unhinge but the truth is Sotravel was
          built by an introvert who hated awkward coffee chats and networking
          events. We believe that meeting people while doing heart-pumping
          activities in nature creates more authentic connections. When you're
          hiking up a mountain or kayaking through rapids together, real
          conversations flow naturally - pun intended.
        </p>
      </p>
    ),
  },
  {
    question: "Guide versus group leader",
    answer: (
      <>
        <p>
          <p className="tw-font-bold tw-mb-2">What is the difference?</p>
          <p>
            Our guides are the destination experts that takes care of your
            logistics, provide cultural insights, and ensure your safety
            throughout the journey. Group leaders are participants that have
            been tasked to help bring the vibes & improve the overall trip
            experience.
          </p>
        </p>
      </>
    ),
  },
  {
    question: "When do I book my flights for the trip",
    answer: (
      <p>
        <p className="tw-font-bold tw-mb-2">When should I book my flights?</p>
        <p>
          We recommend booking your flights only after your trip is confirmed,
          which typically happens when we reach the minimum group size. Once
          confirmed, we'll notify you immediately so you can arrange your
          travel. For the best rates and availability, we suggest booking your
          flights at least 6-8 weeks before the trip start date. Remember to
          check the trip's arrival and departure details before booking.
        </p>
      </p>
    ),
  },
];

export const whyChooseUs = [
  {
    title: "A community of like-minded people",
    description: (
      <>
        “Met <span className="tw-text-primary-5">10 strangers</span> on the trip
        and created really{" "}
        <span className="tw-text-primary-5">amazing memories</span> with them!”
      </>
    ),
    thumbnailSrc: "/landing/meet-likeminded.png",
    reviewer: {
      name: "Clarice Yeo",
      profilePictureSrc: "/landing/clarice-yeo.png",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 14%, rgba(0, 0, 0, 0) 28.5%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0.4) 77.26%)",
  },
  {
    title: "Unique and action-packed itinerary",
    description: (
      <>
        Surfed for the first time; it was an{" "}
        <span className="tw-text-primary-4">incredible adrenaline rush</span>!”
      </>
    ),
    thumbnailSrc: "/landing/unique-and-packed.png",
    reviewer: {
      name: "Shannon Tan",
      profilePictureSrc: "/landing/tan-shannon.png",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 21.05%, rgba(0, 0, 0, 0) 29.88%, rgba(0, 0, 0, 0) 68.91%, rgba(0, 0, 0, 0.5) 81.27%)",
  },
  {
    title: "Hard-to-do-alone adventures",
    description: (
      <>
        “Without the{" "}
        <span className="tw-text-primary-4">support and encouragement</span>{" "}
        from those around me, I would not have made it up the mountain &
        conquered my fears.”
      </>
    ),
    thumbnailSrc: "/landing/hard-to-do-alone.jpeg",
    reviewer: {
      name: "Kimberly Tham",
      profilePictureSrc: "/landing/kimberly-tham.png",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.29) 15.56%, rgba(0, 0, 0, 0) 23.15%, rgba(0, 0, 0, 0) 69.8%, rgba(0, 0, 0, 0.58) 74.58%)",
  },
  {
    title: "Travel without stress",
    description: (
      <>
        “The team was really helpful and made sure{" "}
        <span className="tw-text-primary-4">
          everything was set up perfectly
        </span>
        .”
      </>
    ),
    thumbnailSrc: "/landing/zero-planning.jpeg",
    reviewer: {
      name: "Kent Lin",
      profilePictureSrc: "/landing/kent-lin.png",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.312) 18.89%, rgba(0, 0, 0, 0) 33.94%, rgba(0, 0, 0, 0) 53.36%, rgba(0, 0, 0, 0.468) 75.46%)",
  },
];

export const adventurerReviews = [
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/988209IMG_2363.jpg",
    reviewTo: "Nicolette",
    review:
      "“Sweetest and most reckless person ever! Her laugh is unlike any other - don’t be scared if she laughs out of nowhere 👀”",
    reviewFrom: "Lynn",
    reviewFromProfilePictureSrc:
      "https://sotravel-uploads.s3.ap-southeast-1.amazonaws.com/1694246144308_blob.jpeg",
    tripName: "Mongolia",
  },
  {
    thumbnailSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/1716916179704_blob.jpeg",
    reviewTo: "Olivia",
    reviewFrom: "Dion",
    review:
      "“She’s got the whole package and is Sotravel's best trip leader. Passionate, driven, typical surf mum. Her good vibes rubs off on people. 9/10 host, minus 1 for being Cinderella.”",
    reviewFromProfilePictureSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/1714648713505_blob.jpeg",
    tripName: "Indonesia",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/838244image_2024-12-03_21-46-06.png",
    reviewTo: "Martin",
    review:
      "“This guy is legit 💯 Don’t be intimidated by his big muscles - he is mature, has leadership skills and is also the life of any party. Also a natural boudoir model 🔞”",
    reviewFrom: "Shi Zheng",
    reviewFromProfilePictureSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/1717684151409_blob.png",
    tripName: "Bali",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/383509dac3826138cbad4d600f6a01f1ade0bb.png",
    reviewTo: "Christalle",
    reviewFrom: "Kyli",
    review: "“MUMMY”",
    reviewFromProfilePictureSrc:
      "https://sotravel-uploads.s3.amazonaws.com/1712114972710_blob.jpeg",
    tripName: "The French Alps",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/158857photo_2023-05-28_06-26-42.jpg",
    reviewTo: "Wilfred",
    reviewFrom: "Jia Wei",
    review:
      "“Big korkor. Reliable and always there to help carry that water bottles and snacks. Can steal chocolate from his bag.”",
    reviewFromProfilePictureSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/50170tQz2gr4m_WUjfpl4TySIeexJPsU65lVdWjuelfr4uj0MfawqBTBrIgj8CMGgQQegVshSLd1zhgGmdEgy5Skn1JvfJHkSCdlPzc0fujsB0gkdwOin9xIiH4Uhypvf3pBtAlaVeasvV4cHmTz9acC7DnTHgAQPVlRCu4r_CDNWGRb8otO8C9qiMzSng1Lzg9r8t7XT70s6kmYvgIkfcdL7Ym9bDfG7LEStVSECZDeqDNuR37vo.jpg",
    tripName: "China",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/1286924IMG_2365.jpg",
    reviewTo: "Emmalyn",
    reviewFrom: "Larry",
    review:
      "“Met Emmalyn on the Great Singapore Ski Trip and she's a certified gaslighter. Avoid at all cost.”",
    reviewFromProfilePictureSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/1722276134007_blob.jpeg",
    tripName: "a light house",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/884895image_2024-12-04_16-41-10.png",
    reviewTo: "Jolynn",
    reviewFrom: "Pansy",
    review:
      "“It's never boring with Jo around! She can also step up as a leader for the group whenever needed. Her bubble ring is 10/10 woohoo”",
    reviewFromProfilePictureSrc:
      "https://sotravel-uploads.s3.amazonaws.com/1702823503300_blob.jpeg",
    tripName: "Philippines",
  },
  {
    thumbnailSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/164510photo_2024-04-10_22-28-55.jpg",
    reviewTo: "Evan",
    reviewFrom: "Azreena",
    review: "“Solid baller, always bringing the party to everyone 🍻”",
    reviewFromProfilePictureSrc:
      "https://sotravel-agent-folder.s3.ap-southeast-1.amazonaws.com/%2Frandom/136265photo_2024-11-10_23-37-39.jpg",
    tripName: "Europe",
  },
  {
    thumbnailSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/1731372276095_blob.jpeg",
    reviewTo: "Erica",
    reviewFrom: "Guan Yew",
    review:
      "“Whomsoever hath the good fortune to meet erica yall get ready to become an NPC and book another 3 trips cos she spreading some snowboard addiction virus”",
    reviewFromProfilePictureSrc:
      "https://sotravel-uploads.s3.ap-southeast-1.amazonaws.com/1708540998398_blob.jpeg",
    tripName: "Japan",
  },
];
