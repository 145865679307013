import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./calendar.css";
import { useTabletOrMobile } from "../../hooks/responsive";
import { createPortal } from "react-dom";
import { dateRangeFormattingShortYear } from "../../utils/utils.js";
import { getDisabledProjectDates } from "../../services/project";

const MobileCalendar = ({
  showCalendar,
  setShowCalendar,
  range,
  setRange,
  numMonths,
  handleLoadMore,
  formatDate,
  autoDuration,
  disabledDates,
  defaultMonthDate,
}) => {
  const [localRange, setLocalRange] = useState(range);

  const handleSave = () => {
    setRange(localRange);
    setShowCalendar(false);
  };

  const handleClear = () => {
    setLocalRange({ from: undefined, to: undefined });
  };

  const handleSelect = (range, triggerDate) => {
    if (autoDuration && triggerDate) {
      const days = parseInt(autoDuration.split("D")[0]);
      const from = new Date(triggerDate);
      const to = new Date(triggerDate);
      to.setDate(to.getDate() + days - 1);
      setLocalRange({ from, to });
    } else {
      setLocalRange(range);
    }
  };

  return createPortal(
    <AnimatePresence>
      {showCalendar && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ type: "tween" }}
          className="tw-fixed tw-inset-x-0 tw-top-[16px] tw-bottom-0 tw-bg-white tw-z-[2000] tw-shadow-xl tw-rounded-t-2xl"
        >
          <div className="tw-h-full tw-flex tw-flex-col">
            {/* Header */}
            <div className="tw-p-4 tw-border-b">
              <div className="tw-flex tw-justify-between tw-items-center">
                <button
                  onClick={() => setShowCalendar(false)}
                  className="tw-text-gray-500"
                >
                  ✕
                </button>
              </div>
              <div className="tw-mt-4">
                <h2 className="tw-text-l tw-font-semibold">
                  {localRange.to
                    ? `Selected: ${dateRangeFormattingShortYear(
                        localRange.from,
                        localRange.to
                      )}`
                    : "Select trip start date"}
                </h2>
              </div>
              <div className="tw-flex tw-mt-4">
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Su</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Mo</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Tu</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">We</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Th</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Fr</div>
                <div className="tw-w-[44px] tw-text-center tw-text-sm">Sa</div>
              </div>
            </div>

            {/* Calendar */}
            <div className="tw-flex-1 tw-overflow-auto tw-p-4">
              <DayPicker
                mode="range"
                selected={localRange}
                onSelect={handleSelect}
                numberOfMonths={numMonths}
                pagedNavigation
                hideWeekdays
                disabled={disabledDates}
                defaultMonth={defaultMonthDate}
                modifiersStyles={{
                  disabled: {
                    textDecoration: "line-through",
                    color: "#9ca3af", // Tailwind's text-gray-500 equivalent
                    pointerEvents: "none", // Optional: Make disabled days unclickable
                  },
                }}
              />
            </div>

            {/* Footer */}
            <div className="tw-p-4 tw-border-t">
              <div className="tw-flex tw-justify-end">
                <button
                  onClick={handleClear}
                  className="tw-text-gray-500 tw-underline tw-mr-4"
                >
                  Clear
                </button>

                <button
                  onClick={handleSave}
                  disabled={!localRange.from || !localRange.to}
                  className={`tw-px-6 tw-py-2 tw-text-white tw-font-bold tw-rounded-lg tw-transition-colors ${
                    localRange.from && localRange.to
                      ? "tw-bg-[deepskyblue] hover:tw-bg-[#00a0e6]"
                      : "tw-bg-gray-300 tw-cursor-not-allowed"
                  }`}
                >
                  Select dates
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

const DesktopCalendar = ({
  showCalendar,
  setShowCalendar,
  range,
  setRange,
  formatDate,
  autoDuration,
  disabledDates,
  defaultMonthDate,
}) => {
  const [localRange, setLocalRange] = useState(range);

  const handleSave = () => {
    setRange(localRange);
    setShowCalendar(false);
  };

  const handleClear = () => {
    setLocalRange({ from: undefined, to: undefined });
  };

  const handleSelect = (range, triggerDate) => {
    if (autoDuration && triggerDate) {
      const days = parseInt(autoDuration.split("D")[0]);
      const from = new Date(triggerDate);
      const to = new Date(triggerDate);
      to.setDate(to.getDate() + days - 1);
      setLocalRange({ from, to });
    } else {
      setLocalRange(range);
    }
  };

  return showCalendar ? (
    <div className="calendar-container tw-fixed tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-bg-white tw-rounded-xl tw-shadow-2xl tw-transition-shadow tw-duration-300 tw-p-6 tw-min-w-[700px] tw-h-[30rem]">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-text-gray-700">
          {localRange.from && localRange.to ? (
            <>
              <div style={{ fontSize: "16px", color: "black" }}>
                Selected:{" "}
                {dateRangeFormattingShortYear(localRange.from, localRange.to)}
              </div>
            </>
          ) : (
            <div className="tw-text-gray-500">Select trip start date</div>
          )}
        </div>
      </div>

      <DayPicker
        mode="range"
        selected={localRange}
        onSelect={handleSelect}
        numberOfMonths={2}
        pagedNavigation
        disabled={disabledDates}
        defaultMonth={defaultMonthDate}
        modifiersStyles={{
          disabled: {
            textDecoration: "line-through",
            color: "#9ca3af", // Tailwind's text-gray-500 equivalent
            pointerEvents: "none", // Optional: Make disabled days unclickable
          },
        }}
      />

      <div className="tw-flex tw-justify-end tw-gap-3 tw-absolute tw-bottom-8 tw-right-8">
        <button
          onClick={handleClear}
          className="tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-text-gray-400 tw-underline tw-transition-colors"
        >
          Clear
        </button>
        <button
          onClick={handleSave}
          disabled={!localRange.from || !localRange.to}
          className={`tw-px-4 tw-py-2 tw-text-white tw-rounded-lg tw-transition-colors ${
            localRange.from && localRange.to
              ? "tw-bg-[deepskyblue] hover:tw-bg-[#00a0e6]"
              : "tw-bg-gray-300 tw-cursor-not-allowed"
          }`}
        >
          Select dates
        </button>
      </div>
    </div>
  ) : null;
};

export default function Calendar({
  range,
  setRange,
  showCalendar,
  setShowCalendar,
  autoDuration,
  projectId,
}) {
  const [numMonths, setNumMonths] = useState(2);

  const isMobile = useTabletOrMobile();

  const formatDate = (date) => {
    if (!date) return "";

    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleLoadMore = () => {
    setNumMonths((prev) => prev + 2);
  };

  // Add click event listener to handle clicks outside calendar
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !isMobile &&
        showCalendar &&
        !e.target.closest(".calendar-container")
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, showCalendar, setShowCalendar]);

  const [disabledDates, setDisabledDates] = useState([{ before: new Date() }]);
  const [defaultMonthDate, setDefaultMonthDate] = useState(new Date());
  useEffect(() => {
    const fetchDisabledDates = async () => {
      try {
        const response = await getDisabledProjectDates(projectId);
        if (response?.data) {
          setDisabledDates(response.data.disabledDates);
          setDefaultMonthDate(response.data.earliestAvailableDate);
        }
      } catch (error) {
        console.error("Error fetching disabled dates:", error);
      }
    };

    fetchDisabledDates();
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileCalendar
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          range={range}
          setRange={setRange}
          numMonths={numMonths}
          handleLoadMore={handleLoadMore}
          formatDate={formatDate}
          autoDuration={autoDuration}
          disabledDates={disabledDates}
          defaultMonthDate={defaultMonthDate}
        />
      ) : (
        <DesktopCalendar
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          range={range}
          setRange={setRange}
          formatDate={formatDate}
          autoDuration={autoDuration}
          disabledDates={disabledDates}
          defaultMonthDate={defaultMonthDate}
        />
      )}
    </>
  );
}
