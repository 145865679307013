import React, { useEffect, useState } from "react";
import "./Home.scss";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/system";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { useGlobalState } from "../GlobalStates";
import { ImageResizable } from "../components/elements/ImageResizeable.js";
import { Tooltip } from "@mui/material";

let website_url = "https://www.sotravel.com";
if (process.env.NODE_ENV === "development") {
  website_url = "http://localhost:3000";
}

export const ProjectBox = ({
  id,
  trip_id,
  project_id,
  name,
  main_images,
  max_slots,
  price,
  skill_level,
  duration,
  country,
  city,
  highlights,
  three_user_images,
  main_video,
  boxStyle,
  containerStyle,
  interestedBy,
}) => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = useGlobalState("promotion");

  return (
    <div className="HomeTripContainer" style={containerStyle}>
      <button
        className="HomeTripBox"
        onClick={() => {
          if (isMobile) {
            navigate(`/trips/${project_id}`, {
              state: {
                main_images: main_images,
                main_video: main_video,
                name: name,
                country: country,
                city: city,
                duration: duration,
                skill_level: skill_level,
              },
            });
          } else {
            window.open(`${website_url}/trips/${project_id}`, "_blank");
          }
        }}
        style={boxStyle}
      >
        <div className="tw-relative tw-w-full tw-h-full">
          <Carousel
            className="HomeImageCarousel"
            navButtonsAlwaysInvisible={isMobile}
            autoPlay={false}
            animation="slide"
            duration={300}
            indicators={true}
            indicatorIconButtonProps={{
              style: {
                padding: "1px",
                color: "lightgray",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "white",
              },
            }}
            indicatorContainerProps={{
              style: {
                marginTop: "-30px",
                position: "absolute",
                zIndex: "10",
              },
            }}
            style={{ zIndex: 1 }}
          >
            {main_images &&
              main_images.map((imageUrl, index) => (
                <ImageResizable
                  key={index}
                  src={imageUrl}
                  className="HomeImage"
                  fadeIn={true}
                  size={isMobile ? "l" : "xl"}
                />
              ))}
          </Carousel>

          {interestedBy && interestedBy.length > 0 && (
            <div className="tw-absolute tw-z-10 tw-top-3 tw-left-3 tw-flex tw-items-center tw-bg-black/70 tw-backdrop-blur-sm tw-px-3 tw-py-1.5 tw-rounded-full tw-shadow-lg hover:tw-bg-black/80 tw-transition-all tw-duration-200">
              <div className="tw-flex tw-items-center">
                <div className="tw-flex tw--space-x-2">
                  {interestedBy.slice(0, 3).map((person, idx) => (
                    <Tooltip
                      key={idx}
                      title={`${person.first_name} is interested in this trip`}
                      placement="bottom"
                    >
                      <div className="tw-w-7 tw-h-7 tw-rounded-full tw-border-2 tw-border-gray-800 tw-overflow-hidden tw-transition-transform hover:tw-scale-110">
                        <ImageResizable
                          src={person.image}
                          size="xs"
                          className="tw-w-full tw-h-full tw-object-cover"
                        />
                      </div>
                    </Tooltip>
                  ))}
                </div>
                <span className="tw-ml-2.5 tw-text-sm tw-font-medium tw-text-white">
                  {interestedBy.length > 3
                    ? `+${interestedBy.length - 3} others interested`
                    : `${interestedBy.length} interested`}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="HomeTripSummary">
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            width="100%"
          >
            <span
              style={{
                color: "#222222",
                fontWeight: "600",
                width: "100%",
                textAlign: "left",
              }}
            >
              {name}
            </span>
          </Stack>

          <Stack
            direction="row"
            spacing={0.6}
            alignItems="center"
            style={{ marginTop: "2px", marginBottom: "1px" }}
          >
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {country}
            </span>
            <FiberManualRecordIcon
              style={{ fontSize: "3px", color: "#333333" }}
            />
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {duration}
            </span>
            <FiberManualRecordIcon
              style={{ fontSize: "3px", color: "#333333" }}
            />
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {skill_level}
            </span>
          </Stack>

          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            marginTop="5px"
          >
            {highlights &&
              highlights.map((highlight, i) => (
                <div className="ProjectBoxHighlights" key={highlight.key}>
                  <div className="ProjectBoxHighlightsRow">
                    <div className="ProjectBoxHighlightsRowLeft">
                      <StarIcon style={{ fontSize: "10px", margin: "4px" }} />
                    </div>
                    <div className="ProjectBoxHighlightsRowRight">
                      <p className="ProjectBoxHighlightsRowRightDesc">
                        {highlight.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </Stack>
        </div>

        <div
          className="HomeTripDetailsGroupButton"
          style={{ marginTop: "10px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <div className="HomeTripDetailsGroup">
              <div style={{ paddingTop: "1.3rem" }}></div>
              {three_user_images &&
                three_user_images.map((imageUrl, index) => (
                  <ImageResizable
                    size="xs"
                    key={index}
                    className="HomeTripDetailsGroupMembersProfile"
                    src={imageUrl}
                    style={
                      index === 1
                        ? { marginLeft: "1rem", animationDelay: "0.3s" }
                        : index === 2
                        ? {
                            marginLeft: "2rem",
                            animationDelay: "0.6s",
                          }
                        : {}
                    }
                  />
                ))}

              <span
                className="HomeTripDetailsGroupSlotsSpan"
                style={
                  three_user_images.length > 0
                    ? { fontWeight: 600, color: "black" }
                    : {
                        fontSize: "15px",
                        fontWeight: 600,
                        color: "black",
                      }
                }
              >
                From ${price}
              </span>
            </div>

            <Button className="hometrip-waitlist-button">
              <span>See all dates</span>
            </Button>
          </Stack>
        </div>
      </button>
    </div>
  );
};
