import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import authAxios from "../../authAxios";
import { useIsMobile } from "../../hooks/responsive";
import UserProfileBottomSheet from "./components/UserProfileBottomSheet";

const ImportContacts = () => {
  const isMobile = useIsMobile();
  const isInApp = window.isInApp;

  if (isMobile && isInApp) {
    return <_ImportContacts />;
  }

  return <Navigate to="/profile/connections" />;
};

const _ImportContacts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isInApp = window.isInApp;

  // Get emails and phone numbers from navigation state
  const emails = location.state?.emails || [];
  const phoneNumbers = location.state?.phoneNumbers || [];

  const [contacts, setContacts] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(true);

  const [following, setFollowing] = useState([]);
  const [loadingFollowing, setLoadingFollowing] = useState(false);

  async function getFollowing() {
    setLoadingFollowing(true);
    const { data: following } = await authAxios.get("/v1/social/getFollowing");
    setFollowing(following);
    setLoadingFollowing(false);
  }

  useEffect(() => {
    getFollowing();
  }, []);

  const followingHash = following.reduce((acc, user) => {
    acc[user.following_id] = {
      is_approved: user.is_approved,
    };
    return acc;
  }, {});

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await authAxios.post(
          `/v1/mobileapp/getUsersFromContacts`,
          {
            emails,
            phoneNumbers,
          }
        );

        console.log("log response", response.data);

        const formattedContacts = response.data.map((user, index) => ({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name || "",
          image:
            user.image ||
            `https://api.dicebear.com/6.x/initials/svg?seed=${
              user.email || user.phone_number
            }`,
        }));

        setContacts(formattedContacts);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        setLoadingContacts(false);
      }
    };

    fetchContacts();
  }, [emails, phoneNumbers]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredContacts = contacts.filter((contact) =>
    contact.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAppNavigation = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("connectionNavigationBack");
    } else {
      console.error("ReactNativeWebView bridge is not available");
    }
  };

  const UserCard = ({ user, isFollowing: _isFollowing }) => {
    const [isFollowing, setIsFollowing] = useState(_isFollowing);

    const [showUserProfile, setShowUserProfile] = useState(false);

    return (
      <>
        <div
          className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-sm"
          onClick={() => setShowUserProfile(true)}
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <img
              src={user.image}
              alt={user.username}
              className="tw-w-12 tw-h-12 tw-rounded-xl tw-object-cover"
            />
            <h3 className="tw-font-medium">{user.first_name}</h3>
          </div>
          <button
            onClick={async () => {
              // Optimistically update UI first
              setIsFollowing(!isFollowing);

              try {
                if (isFollowing) {
                  await authAxios.post(`/v1/social/unfollowUser/${user.id}`);
                } else {
                  await authAxios.post(`/v1/social/followUser/${user.id}`);
                }
              } catch (error) {
                // Revert optimistic update on error
                setIsFollowing(isFollowing);
                console.error("Error updating follow status:", error);
              }
            }}
            className={`tw-px-4 tw-py-2 tw-rounded-xl tw-font-medium tw-text-sm ${
              isFollowing
                ? "tw-bg-gray-100 tw-text-gray-600"
                : "tw-bg-[deepskyblue] tw-text-white"
            }`}
          >
            {isFollowing ? "Following" : "Follow"}
          </button>
        </div>
        <UserProfileBottomSheet
          showUserProfile={showUserProfile}
          setShowUserProfile={setShowUserProfile}
          userId={user.id}
        />
      </>
    );
  };

  const loading = loadingContacts || loadingFollowing;

  return (
    <div
      className={`tw-max-w-5xl tw-mx-auto md:tw-mt-[86px] ${
        isInApp ? "tw-py-0" : "tw-py-8"
      }`}
    >
      {/* Header Section */}
      <div className="tw-px-4">
        <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
          {isMobile && (
            <button
              onClick={() => {
                if (isInApp) {
                  handleAppNavigation();
                } else {
                  navigate(-1);
                }
              }}
              className="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-rounded-full tw-transition-all tw-duration-200 tw-ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-5 tw-w-5 tw-text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          <div>
            <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-gray-900">
              Import Contacts
            </h1>
            <p className="tw-mt-1.5 tw-text-gray-600 tw-text-sm md:tw-text-base">
              Find people you know on Sotravel
            </p>
          </div>
        </div>
      </div>

      {/* Search Input */}
      <div className="tw-px-4 tw-mb-6">
        <div className="tw-relative">
          <input
            type="text"
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-full tw-bg-gray-50 focus:tw-bg-white focus:tw-border-[deepskyblue] focus:tw-ring-1 focus:tw-ring-[deepskyblue] tw-transition-colors"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-text-gray-400 tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {/* Contacts List */}
      <div className="tw-px-4">
        {loading ? (
          <div className="tw-text-center tw-py-12">
            <p className="tw-text-gray-500">Loading contacts...</p>
          </div>
        ) : filteredContacts.length > 0 ? (
          <Virtuoso
            useWindowScroll
            totalCount={filteredContacts.length}
            itemContent={(index) => (
              <>
                <UserCard
                  user={filteredContacts[index]}
                  isFollowing={followingHash[filteredContacts[index].id]}
                />
                <div className="tw-h-4"></div>
              </>
            )}
          />
        ) : (
          <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
            <p className="tw-text-gray-500 tw-text-lg">No contacts found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportContacts;
