import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Profile.scss";
import { Link, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import { MobileMenuHeader } from "../components/header/MobileMenuHeader";
import LoadingView from "../components/elements/LoadingView";
import { getUserById } from "../services/user";
import ModeOfTravelOutlinedIcon from "@mui/icons-material/ModeOfTravelOutlined";
import {
  getTrip,
  getUserTrips,
  getUserTrip,
  isUserALocalPartnerOrLeader,
} from "../services/trip";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import ReactPlayer from "react-player";
import { getUserReviews, getUserExtraMedia } from "../services/user";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { capitalize, useTheme } from "@mui/material";
import useQueryParams from "../customHooks/useQueryParams";
import dayjs from "dayjs";
import { ImageResizable } from "../components/elements/ImageResizeable";
import { useGlobalState } from "../GlobalStates";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import authAxios from "../authAxios";
import { useTabletOrMobile } from "../hooks/responsive";

export const UserProfile = ({ passing_user_id = null }) => {
  const { palette } = useTheme();
  const [_, setOpenAlertBannerMsg] = useGlobalState("openAlertBannerMsg");

  const { user_id } = useParams();
  const trip_id = useQueryParams().get("trip_id");
  const [user] = useGlobalState("user");

  const [profile, setProfile] = useState(null);
  const [userPastTrips, setUserPastTrips] = useState([]);
  const [userUpcomingTrips, setUserUpcomingTrips] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [extraMedia, setExtraMedia] = useState([]);
  const [
    { is_trip_leader, is_local_partner, local_partner_country },
    setIsUserALocalPartnerOrLeader,
  ] = useState({ is_trip_leader: false, is_local_partner: false });

  const [following, setFollowing] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = useTabletOrMobile();

  const followingHash = following.reduce((acc, user) => {
    acc[user.following_id] = {
      is_approved: user.is_approved,
    };
    return acc;
  }, {});

  async function getFollowing() {
    const { data: following } = await authAxios.get("/v1/social/getFollowing");
    setFollowing(following);
  }

  useEffect(() => {
    getFollowing();
  }, []);

  const userId = passing_user_id ? passing_user_id : user_id;

  const isUserProfile = userId === user?.id;

  const isInApp = window.isInApp;

  const getButtonStyle = () => {
    if (!followingHash[profile.id]) {
      return "tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600";
    }
    if (!followingHash[profile.id].is_approved) {
      return isHovered
        ? "tw-bg-red-500 tw-text-white"
        : "tw-bg-gray-200 tw-text-gray-800";
    }
    return isHovered
      ? "tw-bg-red-500 tw-text-white"
      : "tw-bg-gray-200 tw-text-gray-800";
  };

  const getButtonText = () => {
    if (!followingHash[profile.id]) {
      return "Follow";
    }

    if (!followingHash[profile.id].is_approved) {
      return isMobile
        ? "Cancel Request"
        : isHovered
        ? "Cancel Request"
        : "Requested";
    }
    return isMobile ? "Unfollow" : isHovered ? "Unfollow" : "Following";
  };

  const handleFollowClick = async () => {
    try {
      if (!followingHash[profile.id]) {
        // Optimistically add to following list
        setFollowing((prev) => [
          ...prev,
          {
            following_id: profile.id,
            following_first_name: profile.first_name,
            following_last_name: profile.last_name,
            following_username: profile.username,
            following_image: profile.image,
            is_approved: true,
          },
        ]);
        await authAxios.post(`/v1/social/followUser/${profile.id}`);
      } else if (!followingHash[profile.id].is_approved) {
        // Optimistically remove from following list
        setFollowing((prev) =>
          prev.filter((f) => f.following_id !== profile.id)
        );
        await authAxios.post(`/v1/social/cancelFollowRequest/${profile.id}`);
      } else {
        // Optimistically remove from following list
        setFollowing((prev) =>
          prev.filter((f) => f.following_id !== profile.id)
        );
        await authAxios.post(`/v1/social/unfollowUser/${profile.id}`);
      }
    } catch (error) {
      console.error("Failed to update follow status:", error);
      // Revert optimistic updates on error
      getFollowing();
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileRes = await getUserById(userId);
        setProfile(profileRes.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getIsUserALocalPartnerOrLeader = async () => {
      const { is_trip_leader, is_local_partner, local_partner_country } = (
        await isUserALocalPartnerOrLeader(userId, trip_id)
      ).data;
      setIsUserALocalPartnerOrLeader({
        is_trip_leader,
        is_local_partner,
        local_partner_country,
      });
    };

    const getUserPastSotravelTrips = async () => {
      const res = await getUserTrips(userId);
      setUserUpcomingTrips(res.data.upcomingTrips);
      setUserPastTrips(res.data.pastTrips);
    };

    const getReviews = async () => {
      try {
        const res = await getUserReviews(userId);
        const mediaRes = await getUserExtraMedia(userId);
        setReviews(res.data);
        setExtraMedia(mediaRes.data);
      } catch (err) {
        console.log("failed to get user reviews", err);
      }
    };

    fetchProfile();
    getUserPastSotravelTrips();
    getReviews();

    if (trip_id) {
      getIsUserALocalPartnerOrLeader();
    }
  }, [trip_id, userId]);

  const scrollValue = 298;
  const carouselReviewRef = useRef(null);
  const scrollReviewLeft = () => {
    carouselReviewRef.current.scrollLeft -= scrollValue; // Adjust the scroll distance as needed
  };
  const scrollReviewRight = () => {
    carouselReviewRef.current.scrollLeft += scrollValue; // Adjust the scroll distance as needed
  };
  const carouselMediaRef = useRef(null);
  const scrollMediaLeft = () => {
    carouselMediaRef.current.scrollLeft -= scrollValue; // Adjust the scroll distance as needed
  };
  const scrollMediaRight = () => {
    carouselMediaRef.current.scrollLeft += scrollValue; // Adjust the scroll distance as needed
  };

  const displayExtraMedia = (url, type) => {
    if (type === "image") {
      return (
        <img
          src={url}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      );
    } else if (type === "video") {
      return (
        <ReactPlayer
          url={url}
          controls
          playing={false}
          width="100%"
          height="100%"
        />
      );
    }
  };
  const [reviewMoreIndex, setReviewMoreIndex] = useState(-1);
  const reviewMaxLength = 120;
  const shortenText = (text) => {
    if (text.length === 0) {
      const positiveWords = ["Awesome!", "Great!", "Amazing!", "Fantastic!"];
      return positiveWords[Math.floor(Math.random() * positiveWords.length)];
    }
    if (text.length > reviewMaxLength) {
      return `"${text.slice(0, reviewMaxLength)} ...`;
    }
    return `"${text}"`;
  };

  const handleAppNavigation = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("connectionNavigationTo");
    } else {
      console.error("ReactNativeWebView bridge is not available");
    }
  };

  if (!profile) {
    return <LoadingView />;
  }

  return (
    <div className="ProfileWrapper">
      <div className="ProfileContainer">
        <div className="ProfileImageAndDescContainer">
          <div className="ProfileImageContainer">
            <img src={profile.image} alt={profile.first_name} />
            {(is_trip_leader || is_local_partner) && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: "0.1rem",
                  right: "0.1rem",
                  height: "2.5rem",
                  width: "2.5rem",
                  borderRadius: "50%",
                  backgroundColor: palette.primary.main,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {is_local_partner ? (
                  <Icon
                    icon="ic:round-verified-user"
                    color="white"
                    fontSize={"1.8rem"}
                  />
                ) : (
                  <Icon icon="mdi:crown" color="white" fontSize={"1.8rem"} />
                )}
              </div>
            )}
          </div>

          <Stack className="ProfileSocialsContainer">
            {isInApp ? (
              isUserProfile ? (
                <button
                  className="ProfileFollowContainer"
                  onClick={() => {
                    handleAppNavigation();
                  }}
                >
                  <span className="ProfileFollowNumber">
                    {user.following_count}
                  </span>
                  <span className="ProfileFollowText">Following</span>
                </button>
              ) : (
                <button
                  className="ProfileFollowContainer"
                  onClick={() => {
                    handleAppNavigation();
                  }}
                >
                  <span className="ProfileFollowNumber">
                    {profile.following_count}
                  </span>
                  <span className="ProfileFollowText">Following</span>
                </button>
              )
            ) : (
              <Link
                to={
                  isUserProfile
                    ? `/profile/connections`
                    : `/profile/${user_id}/connections`
                }
              >
                <button className="ProfileFollowContainer">
                  <span className="ProfileFollowNumber">
                    {isUserProfile
                      ? user.following_count
                      : profile.following_count}
                  </span>
                  <span className="ProfileFollowText">Following</span>
                </button>
              </Link>
            )}

            {isInApp ? (
              isUserProfile ? (
                <button
                  className="ProfileFollowContainer"
                  onClick={() => {
                    handleAppNavigation();
                  }}
                >
                  <span className="ProfileFollowNumber">
                    {user.follower_count}
                  </span>
                  <span className="ProfileFollowText">Followers</span>
                </button>
              ) : (
                <button
                  className="ProfileFollowContainer"
                  onClick={() => {
                    handleAppNavigation();
                  }}
                >
                  <span className="ProfileFollowNumber">
                    {profile.follower_count}
                  </span>
                  <span className="ProfileFollowText">Followers</span>
                </button>
              )
            ) : (
              <Link
                to={
                  isUserProfile
                    ? `/profile/connections`
                    : `/profile/${user_id}/connections`
                }
              >
                <button className="ProfileFollowContainer">
                  <span className="ProfileFollowNumber">
                    {isUserProfile
                      ? user.follower_count
                      : profile.follower_count}
                  </span>
                  <span className="ProfileFollowText">Followers</span>
                </button>
              </Link>
            )}

            <button className="ProfileFollowContainer">
              <span className="ProfileFollowNumber">{profile.credits}</span>
              <span className="ProfileFollowText">Socredits</span>
            </button>
          </Stack>

          {isUserProfile ? (
            <Stack className="ProfileSocialsContainer">
              <Link to="/trip-starter-form">
                <button className="ProfileEditButton">Edit profile</button>
              </Link>{" "}
              <CopyToClipboard
                text={`Use this link to get up to $40 credits back on your first Sotravel trip! 👉 www.sotravel.com/welcome-discount?code=${user.id}`}
              >
                <button
                  className="ProfileEditButton"
                  onClick={() => {
                    setOpenAlertBannerMsg("Referral link copied");
                  }}
                >
                  Refer & earn
                </button>
              </CopyToClipboard>
            </Stack>
          ) : (
            <Stack className="ProfileSocialsContainer">
              <button
                className={`ProfileEditButton ${getButtonStyle()}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleFollowClick}
                style={{
                  padding: "7px 20px 7px 20px",
                }}
              >
                {getButtonText()}
              </button>{" "}
              <CopyToClipboard text={`www.sotravel.com/profile/${user.id}`}>
                <button
                  className="ProfileEditButton"
                  onClick={() => {
                    setOpenAlertBannerMsg("Profile link copied");
                  }}
                >
                  Share profile
                </button>
              </CopyToClipboard>
            </Stack>
          )}

          <Stack className="ProfileSocialsContainer">
            {profile.socials_instagram && (
              <button
                className="ProfileSocialsButton"
                onClick={() =>
                  window.open(
                    profile.socials_instagram,
                    "_blank",
                    "noopener,noreferrer,resizable"
                  )
                }
              >
                <InstagramIcon
                  style={{
                    fontSize: "17px",
                    marginRight: "4px",
                    color: "deepskyblue",
                  }}
                />
                <span>Instagram</span>
              </button>
            )}
          </Stack>

          {is_trip_leader && (
            <div style={{ width: "100%" }}>
              <p className="ProfileHeader">What is a trip leader?</p>

              <div>
                <div className="ProfileDescription">
                  <span>
                    A trip leader is a <b>participant</b> that we have
                    identified to help make the trip a success! Do help them out
                    by supporting them!
                  </span>
                </div>
              </div>
            </div>
          )}

          {local_partner_country ? (
            <p
              className="ProfileHeader"
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              A word from our {local_partner_country} Team
            </p>
          ) : (
            <p
              className="ProfileHeader"
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              About {profile.first_name}
            </p>
          )}

          <div className="ProfileDescription">{profile.description}</div>

          <Stack className="ProfileExtraInfoContainer" spacing={0.5}>
            {profile.obsessed_with && (
              <div className="ProfileExtraInfoRow">
                <FavoriteBorderOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                <span>Obsessed with: {profile.obsessed_with}</span>
              </div>
            )}

            {profile.certifications && (
              <div className="ProfileExtraInfoRow">
                <ArticleOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                <span>Certifications: {profile.certifications}</span>
              </div>
            )}

            {profile.school_and_subject && (
              <div className="ProfileExtraInfoRow">
                <SchoolOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                <span>School: {profile.school_and_subject}</span>
              </div>
            )}

            {profile.work_and_role && (
              <div className="ProfileExtraInfoRow">
                <WorkOutlineOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                <span>Work: {profile.work_and_role}</span>
              </div>
            )}
          </Stack>

          {reviews.length > 0 && (
            <div className="TripDetailsArrowHeaderContainer">
              <span className="TripDetailsArrowHeader">
                {profile.first_name}'s reviews
              </span>
              <Stack
                className="TripDetailslArrowContainer"
                flexDirection="row"
                justifyContent="space-between"
              >
                <button onClick={scrollReviewLeft}>
                  <ExpandCircleDownOutlinedIcon className="TripDetailsArrow left" />
                </button>

                <button onClick={scrollReviewRight}>
                  <ExpandCircleDownOutlinedIcon className="TripDetailsArrow right" />
                </button>
              </Stack>
            </div>
          )}
          {reviews.length > 0 && (
            <Stack
              className="TripDetailsCarouselItemContainer"
              ref={carouselReviewRef}
            >
              {reviews.map((review, index) => {
                return (
                  <div
                    className="TripDetailsCarouselItemBox"
                    style={
                      reviewMoreIndex === index
                        ? {
                            minWidth: review.positive.length * 2.1,
                          }
                        : {}
                    }
                  >
                    <span>
                      {reviewMoreIndex === index
                        ? `"${review.positive}"`
                        : shortenText(review.positive)}{" "}
                      {review.positive.length > reviewMaxLength &&
                        reviewMoreIndex !== index && (
                          <span
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                            onClick={() => setReviewMoreIndex(index)}
                          >
                            more
                          </span>
                        )}
                    </span>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      className="TripDetailsReviewImgWrapper"
                    >
                      <div className="TripDetailsReviewImgContainer">
                        <img src={review.review_by_image} />
                      </div>
                      <span>{review.review_by_first_name}</span>
                    </Stack>
                  </div>
                );
              })}
            </Stack>
          )}

          {userUpcomingTrips.length > 0 && (
            <>
              <p className="ProfileHeader" style={{ marginTop: "1.5rem" }}>
                Upcoming trips
              </p>
              <div className="ProfileDescription">
                {userUpcomingTrips.map((trip, index) => (
                  <TripCard trip={trip} index={index} />
                ))}
              </div>
            </>
          )}

          {userPastTrips.length > 0 && (
            <>
              <p className="ProfileHeader">Previously attended</p>
              <div className="ProfileDescription">
                {userPastTrips.map((trip, index) => (
                  <TripCard trip={trip} index={index} />
                ))}
              </div>
            </>
          )}

          {extraMedia.length > 0 && (
            <div className="TripDetailsArrowHeaderContainer">
              <span className="TripDetailsArrowHeader">
                {profile.first_name} in action
              </span>
              <Stack
                className="TripDetailslArrowContainer"
                flexDirection="row"
                justifyContent="space-between"
              >
                <button onClick={scrollMediaLeft}>
                  <ExpandCircleDownOutlinedIcon className="TripDetailsArrow left" />
                </button>

                <button onClick={scrollMediaRight}>
                  <ExpandCircleDownOutlinedIcon className="TripDetailsArrow right" />
                </button>
              </Stack>
            </div>
          )}

          {extraMedia.length > 0 && (
            <Stack
              className="TripDetailsCarouselItemContainer"
              ref={carouselMediaRef}
            >
              {extraMedia.map((media, index) => (
                <div className="TripDetailsCarouselMediaBox">
                  <div className="TripDetailsCarouselVideoBox">
                    {displayExtraMedia(media.media_url, media.media_type)}
                  </div>
                </div>
              ))}
            </Stack>
          )}

          <div style={{ width: "100%", height: "3rem" }}></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

function TripCard({ trip, index }) {
  return (
    <Link
      style={{ flex: "none", width: "140px" }}
      to={`/trips/${trip.project_id}`}
      key={trip.id}
      target="_blank"
    >
      {trip.main_images.length && (
        <ImageResizable
          src={trip.main_images[0]}
          alt={`trip-${trip.name}-${index}`}
          style={{
            width: "140px",
            height: "140px",
            objectFit: "cover",
            borderRadius: "10px",
            marginBottom: "0.5rem",
          }}
          size="l"
        />
      )}
      <div style={{ fontWeight: 600 }}>{trip.name}</div>
      <div>{dayjs(trip.start_date).format("DD MMM YY")}</div>
    </Link>
  );
}
