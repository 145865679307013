import React, { useState } from "react";
import "./mainLayout.scss";
import Countdown from "react-countdown";
import { dateDiffMilliSeconds } from "../../utils/utils.js";
import { isMobile } from "react-device-detect";
import { useGlobalState } from "../../GlobalStates.js";
import CloseIcon from "@mui/icons-material/Close";
import useIsAndroid from "../../customHooks/useIsAndroid.js";
import { DownloadAppModal } from "../DownloadAppModal.js";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack } from "@mui/system";

const DownloadAppFooter = ({ children }) => {
  const isInApp = window.isInApp;
  const [showAppHeader, setShowAppHeader] = useState(true);
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isAndroid = useIsAndroid();

  const handleDownloadClick = () => {
    if (isMobile) {
      const appStoreRedirect = isAndroid
        ? "https://play.google.com/store/apps/details?id=com.sotravel.sotravel"
        : "https://apps.apple.com/app/id6447177449";
      window.open(appStoreRedirect);
      setShowAppHeader(false);
    } else {
      setOpenDownloadAppModal(true);
    }
  };

  const handleSeeDealsClick = () => {
    window.open(promotion?.generalPromo.cta, "_blank");
  };

  const shouldShowAppHeader = () => {
    if (
      location.pathname.toLowerCase().includes("/all-trips") &&
      showAppHeader &&
      !isInApp &&
      localStorage.getItem("JWT_TOKEN")
    ) {
      return true;
    }
  };

  const [promotion, setPromotion] = useGlobalState("promotion");
  const shouldShowPromoFooter = () => {
    if (
      (location.pathname == "/" ||
        location.pathname.toLowerCase().includes("/all-trips")) &&
      showAppHeader &&
      promotion?.generalPromo
    ) {
      return true;
    }
  };

  if (shouldShowPromoFooter()) {
    return (
      <footer
        style={{
          height: isMobile ? "5rem" : "6rem",
          width: "100%",
          backgroundColor: "black",
          color: "yellow",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
          cursor: "pointer",
          transition: "all 0.2s ease",
          boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
          "&:hover": {
            backgroundColor: "#1a1a1a",
            transform: "translateY(-2px)",
          },
        }}
        onClick={() => window.open(promotion?.generalPromo.cta, "_blank")}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            color: "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowAppHeader(false);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "5rem",
            }}
          >
            <div
              style={{
                height: "5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "900",
                }}
              >
                {promotion.generalPromo.name}
              </span>
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                {promotion?.generalPromo.description}
              </span>
            </div>
          </div>

          {location.pathname.toLowerCase().includes("/all-trips") ? (
            <span style={{ marginTop: "5px", marginBottom: "5px" }}>
              <Countdown
                date={promotion?.generalPromo.promo_end_date_inclusive}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                  return (
                    <Stack flexDirection="column" alignItems="center">
                      {days > 0 && (
                        <p
                          style={{
                            color: "white",
                            fontSize: "13px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                          }}
                        >
                          {days} {days > 1 ? "DAYS" : "DAY"} LEFT
                        </p>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "10.3rem",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          marginRight: "7px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "2rem",
                            width: "3.7rem",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <span style={{ color: "black" }}>
                            {String(hours).padStart(2, "0")} hrs
                          </span>
                        </div>
                        :
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "2rem",
                            width: "2.5rem",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <span style={{ color: "black" }}>
                            {String(minutes).padStart(2, "0")}
                          </span>
                        </div>
                        :
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "2rem",
                            width: "2.5rem",
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <span style={{ color: "black" }}>
                            {String(seconds).padStart(2, "0")}
                          </span>
                        </div>
                      </div>
                    </Stack>
                  );
                }}
              />
            </span>
          ) : (
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                color: "black",
                padding: "0.5rem 0.8rem",
                fontSize: "14px",
                fontWeight: "900",
                borderRadius: "5px",
              }}
              onClick={handleSeeDealsClick}
            >
              BROWSE DEALS
            </button>
          )}
        </div>
      </footer>
    );
  } else if (shouldShowAppHeader())
    return (
      <footer
        style={{
          height: isMobile ? "5rem" : "6rem",
          width: "100%",
          backgroundColor: "rgba(234, 234, 234, 1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
        }}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            color: "black",
          }}
          onClick={() => setShowAppHeader(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "5rem",
            }}
          >
            <img
              src="/new_logo/app-icon.png"
              style={{
                height: "3.7rem",
                width: "3.7rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15%",
              }}
            />

            <div
              style={{
                width: "10rem",
                height: "5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "900",
                }}
              >
                SOTRAVEL APP
              </span>
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Get access to free reservations and deals
              </span>
            </div>
          </div>
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "deepskyblue",
              color: "white",
              padding: "0.5rem 0.8rem",
              fontSize: "14px",
              fontWeight: "900",
            }}
            onClick={handleDownloadClick}
          >
            DOWNLOAD
          </button>
        </div>

        {openDownloadAppModal && (
          <DownloadAppModal
            open={openDownloadAppModal}
            handleClose={() => {
              setOpenDownloadAppModal(false);
              setShowAppHeader(false);
            }}
          />
        )}
      </footer>
    );
};

export default DownloadAppFooter;
