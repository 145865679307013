import useSWR from "swr";
import unauthAxios, { unauthAxiosFetcher } from "../axios";

export const useProject = (projectId, projectSlug) => {
  const url = projectId
    ? `/v1/project/getProjectById?project_id=${projectId}`
    : projectSlug
    ? `/v1/project/getProjectBySlug?project_slug=${projectSlug}`
    : null;

  return useSWR(url, unauthAxiosFetcher);
};
export const getProjectCanStartNewDateWithoutApproval = (
  project_id,
  trip_start_date,
  trip_end_date,
  allow_public_trip_creation,
  guest_count,
  trip_min_slots,
  trip_days_before_all_nonrefundable,
  trip_days_before_deposit_nonrefundable
) =>
  unauthAxios.post(`/v1/project/getProjectCanStartNewDateWithoutApproval`, {
    project_id,
    trip_start_date,
    trip_end_date,
    allow_public_trip_creation,
    guest_count,
    trip_min_slots,
    trip_days_before_all_nonrefundable,
    trip_days_before_deposit_nonrefundable,
  });

export const useProjects = () =>
  useSWR("v1/project/getAllProjects", unauthAxiosFetcher);

export const useProjectTrips = (project_id) => {
  return useSWR(
    `/v1/project/getProjectTrips?project_id=${project_id}`,
    unauthAxiosFetcher
  );
};

export const getProjectCommonQuestions = (project_id) =>
  unauthAxios.get(
    `/v1/project/getProjectCommonQuestions?project_id=${project_id}`
  );

export const getProjectTrips = (project_id) =>
  unauthAxios.get(`/v1/project/getProjectTrips?project_id=${project_id}`);

export const getProjectItinerary = (project_id) =>
  unauthAxios.get(`/v1/project/getProjectItinerary?project_id=${project_id}`);

export const getProjectItineraryHighlights = (project_id) =>
  unauthAxios.get(
    `/v1/project/getProjectItineraryHighlights?project_id=${project_id}`
  );

export const getProjectAccommodationHighlights = (project_id) =>
  unauthAxios.get(
    `/v1/project/getProjectAccommodationHighlights?project_id=${project_id}`
  );

export const getDisabledProjectDates = (project_id) =>
  unauthAxios.get(
    `/v1/project/getDisabledProjectDates?project_id=${project_id}`
  );
