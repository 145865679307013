import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowUpIcon from "../../assets/icons/ArrowUpIcon";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import SotravelIcon from "../../assets/icons/SotravelIcon";
import { faqs } from "../../constants/landing-new";

import GoogleReviewIcon from "../../assets/icons/GoogleReviewIcon";
import StarIcon from "../../assets/icons/StarIcon";
import { companyReviews } from "../../constants/landing-new";

import { useTabletOrMobile, useIsDesktop } from "../../hooks/responsive";
import { AutoPlaySilentVideo } from "../../components/video";

export const StickyHeader = ({ withoutCta }) => {
  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-p-4 md:tw-py-4 md:tw-px-12 tw-gap-4 tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bg-black/10 tw-backdrop-blur-sm tw-z-20">
        <div className="tw-grow md:tw-grow-0">
          <Link to="/all-trips">
            <SotravelIcon className="tw-h-8 md:tw-h-8" />
          </Link>
        </div>

        {!withoutCta && (
          <Link to="/all-trips">
            <button className="tw-bg-primary-main tw-text-white tw-rounded-full tw-px-4 md:tw-px-8 tw-py-2 md:tw-py-3 tw-text-sm md:tw-text-base tw-font-bold hover:tw-bg-primary-dark tw-transition-colors">
              View all trips
            </button>
          </Link>
        )}
      </div>
    </>
  );
};

export const HeroFeatured = ({
  desktopVideo,
  mobileVideo,
  mainText,
  subText,
  ctaText,
  ctaLink,
  onCtaClick,
}) => {
  const isMobile = useTabletOrMobile();
  const isDesktop = useIsDesktop();

  return (
    <div className="tw-h-[calc(100vh)]">
      <div className="tw-relative">
        {/* Hero Text - Responsive styling */}
        <div className="tw-absolute tw-top-[calc(48px+68px)] md:tw-top-[30vh] tw-left-4 tw-right-4 md:tw-left-[15%] md:tw-right-[15%] tw-z-10">
          <h1 className="tw-text-[22px] md:tw-text-5xl tw-font-extrabold tw-text-white tw-text-center">
            {mainText}
          </h1>
          <p className="tw-text-[12px] md:tw-text-2xl tw-font-semibold tw-text-white tw-text-center">
            {subText}
          </p>
        </div>

        {/* CTA Section - Responsive positioning and styling */}
        <div className="tw-absolute tw-left-4 tw-right-4 md:tw-left-[15%] md:tw-right-[15%] tw-bottom-[calc(24px+84px)] md:tw-bottom-[20vh] tw-z-10">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-gap-4 md:tw-gap-8 tw-text-white">
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
              <div className="tw-text-sm md:tw-text-base">4.9 Stars</div>
              <div className="tw-flex tw-gap-0.5 tw-items-center">
                <StarIcon className="md:tw-w-6 md:tw-h-6" />
                <StarIcon className="md:tw-w-6 md:tw-h-6" />
                <StarIcon className="md:tw-w-6 md:tw-h-6" />
                <StarIcon className="md:tw-w-6 md:tw-h-6" />
                <StarIcon className="md:tw-w-6 md:tw-h-6" />
              </div>
              <GoogleReviewIcon className="md:tw-w-6 md:tw-h-6" />
            </div>
            {ctaLink ? (
              <Link to={ctaLink} onClick={onCtaClick}>
                <button className="tw-bg-primary-main tw-text-white tw-rounded-full tw-px-6 md:tw-px-10 tw-py-3 md:tw-py-4 tw-text-base md:tw-text-lg tw-font-bold hover:tw-bg-primary-dark tw-transition-colors">
                  {ctaText}
                </button>
              </Link>
            ) : (
              <button
                onClick={onCtaClick}
                className="tw-bg-primary-main tw-text-white tw-rounded-full tw-px-6 md:tw-px-10 tw-py-3 md:tw-py-4 tw-text-base md:tw-text-lg tw-font-bold hover:tw-bg-primary-dark tw-transition-colors"
              >
                {ctaText}
              </button>
            )}
          </div>
        </div>

        {/* Hero Video/Image - Responsive sizing and overlay */}
        <div className="tw-relative tw-w-full tw-h-[calc(100vh)]">
          <div className="tw-absolute tw-inset-0 tw-bg-gradient-to-b tw-from-black/10 tw-to-black/10"></div>

          {/* Fallback Image */}
          {/* <img
            src="https://images.unsplash.com/photo-1682905926517-6be3768e29f0?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="hero"
            className={`tw-w-full tw-h-full tw-object-cover tw-object-center ${
              isVideoLoaded ? "tw-hidden" : ""
            }`}
          /> */}

          {isDesktop && (
            <>
              {/* Desktop Video */}
              <AutoPlaySilentVideo
                className="md:tw-block tw-w-full tw-h-full tw-object-cover tw-object-center"
                video={desktopVideo}
              />
            </>
          )}

          {isMobile && (
            <>
              {/* Mobile Video */}
              <AutoPlaySilentVideo
                className="tw-w-full tw-h-full tw-object-cover tw-object-center"
                video={mobileVideo}
              />
            </>
          )}
        </div>

        {/* Company Reviews Section - Responsive layout */}
        <div className="tw-absolute tw-top-[calc(100vh-55.5px)] tw-left-0 tw-right-0 tw-z-10">
          <div className="tw-container tw-mx-auto">
            <div className="tw-flex tw-gap-4 tw-overflow-x-auto md:tw-justify-center [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-pb-4 tw-px-4">
              {companyReviews.map(({ review, imageSrc }) => (
                <div
                  className="tw-flex-none md:tw-flex-1 md:tw-max-w-[250px] tw-rounded-2xl tw-shadow-md tw-bg-white tw-p-3 tw-w-[218.25px] tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-2"
                  key={review}
                >
                  <img
                    alt="review"
                    src={imageSrc}
                    className="tw-w-[103.23px] md:tw-w-[120px]"
                  />
                  <div className="tw-text-xs md:tw-text-sm tw-text-[#6C757D] tw-text-center">
                    {review}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhyChooseUs = ({
  className,
  mobileVariant = "vertical",
  title,
  description,
  reasons,
  removeCta = false,
}) => {
  return (
    <div
      className={`tw-bg-white ${
        mobileVariant === "horizontal"
          ? "tw-py-[40px] md:tw-px-16 lg:tw-px-24 md:tw-py-16"
          : "tw-px-4 md:tw-px-16 lg:tw-px-24 tw-py-[40px] md:tw-py-10"
      } ${className || ""}`}
    >
      <div className="tw-text-[26px] md:tw-text-[32px] tw-font-extrabold tw-text-center">
        {title}
      </div>
      <div className="tw-text-base md:tw-text-lg tw-text-center tw-text-primary-main tw-font-semibold tw-mb-8 md:tw-mb-12 tw-px-2 md:tw-px-0">
        {description}
      </div>
      <div
        className={`${
          mobileVariant === "horizontal"
            ? "tw-flex tw-overflow-x-auto tw-snap-x tw-snap-mandatory [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-px-4 md:tw-px-0"
            : "tw-flex tw-flex-col"
        } md:tw-grid md:tw-grid-cols-4 md:tw-justify-center tw-gap-3 md:tw-gap-6`}
      >
        {reasons.map((card) => (
          <div
            key={card.title}
            className={`${
              mobileVariant === "horizontal"
                ? "tw-flex-none tw-w-[85vw] tw-snap-center"
                : "tw-w-full"
            } md:tw-w-full`}
          >
            <ChooseUsCard {...card} mobileVariant={mobileVariant} />
          </div>
        ))}
      </div>
      {!removeCta && (
        <div className="tw-flex tw-justify-center">
          <Link to="/all-trips">
            <button className="tw-bg-primary-main tw-text-white tw-rounded-2xl tw-px-16 tw-py-3 tw-text-base tw-font-bold tw-w-full tw-mt-10 md:tw-mt-12 hover:tw-bg-primary-dark tw-transition-colors">
              Explore our trips
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export const ChooseUsCard = ({
  title,
  description,
  thumbnailSrc,
  reviewer,
  gradient,
  mobileVariant,
}) => {
  return (
    <div
      className={`tw-relative tw-drop-shadow-[0px_4px_4px_0px_#00000040] tw-rounded-3xl tw-h-[400px]`}
    >
      <img
        alt="review"
        className="tw-rounded-3xl tw-w-full tw-h-[400px] tw-object-cover"
        src={thumbnailSrc}
      />
      <div
        className="tw-absolute tw-inset-0 tw-rounded-3xl"
        style={{ background: gradient }}
      />
      <div className="tw-absolute tw-top-6 md:tw-top-8 tw-left-4 tw-right-4">
        <div className="tw-text-center tw-text-white tw-text-2xl md:tw-text-3xl tw-font-extrabold">
          {title}
        </div>
      </div>
      <div className="tw-absolute tw-bottom-4 md:tw-bottom-6 tw-left-4 tw-right-4 tw-flex tw-flex-col tw-gap-2">
        <div className="tw-text-sm md:tw-text-base tw-text-white tw-font-medium tw-text-center">
          {description}
        </div>
        <div>
          <div className="tw-text-[10px] md:tw-text-xs tw-text-center tw-text-white tw-mb-0.5">
            {reviewer.name}
          </div>
          <div className="tw-flex tw-justify-center">
            <img
              className="tw-w-5 tw-h-5 md:tw-w-6 md:tw-h-6 tw-rounded-full tw-object-cover tw-border-2 tw-border-white"
              alt="person"
              src={reviewer.profilePictureSrc}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="tw-px-4 md:tw-px-16 lg:tw-px-24 tw-py-10 md:tw-py-10">
      <div className="tw-text-[26px] md:tw-text-[32px] tw-font-extrabold tw-text-center">
        Frequently asked questions
      </div>
      <div className="tw-text-base md:tw-text-lg tw-text-primary-main tw-text-center tw-font-semibold tw-mb-6 md:tw-mb-10">
        Have questions? We got the answers!
      </div>
      <div className="md:tw-max-w-3xl md:tw-mx-auto">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`tw-border-b tw-border-x tw-border-[#E9ECEF] ${
              index === faqs.length - 1
                ? "tw-rounded-bl-lg tw-rounded-br-lg"
                : ""
            } ${
              index === 0 && "tw-rounded-tl-lg tw-rounded-tr-lg tw-border-t"
            }`}
          >
            <button
              onClick={() => toggleFAQ(index)}
              className={`tw-w-full tw-px-4 md:tw-px-6 tw-py-3 md:tw-py-4 tw-flex tw-items-center tw-gap-2 hover:tw-bg-[#F8F9FA] tw-transition-colors ${
                openIndex === index ? "tw-bg-[#F8F9FA]" : ""
              }`}
            >
              <div className="tw-text-sm md:tw-text-base tw-grow tw-text-left">
                {faq.question}
              </div>
              <motion.div
                animate={{ rotate: openIndex === index ? 180 : 0 }}
                transition={{ duration: 0.3 }}
                className="tw-flex-none tw-text-[#CED4DA]"
              >
                <ChevronDownIcon className="tw-w-5 tw-h-5 md:tw-w-6 md:tw-h-6" />
              </motion.div>
            </button>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="tw-overflow-hidden"
                >
                  <div className="tw-px-4 md:tw-px-6 tw-py-3 md:tw-py-4 tw-border-t tw-border-[#E9ECEF] tw-bg-[#F8F9FA] tw-text-sm md:tw-text-base">
                    {faq.answer}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
      {/* <div className="tw-flex tw-justify-center">
        <button className="tw-bg-primary-main hover:tw-bg-primary-dark tw-text-white tw-rounded-full tw-px-4 md:tw-px-8 tw-py-2 md:tw-py-3 tw-text-sm md:tw-text-base tw-font-bold tw-w-full md:tw-w-auto md:tw-min-w-[200px] tw-mt-8 md:tw-mt-12 tw-transition-colors">
          Read More FAQs
        </button>
      </div> */}
    </div>
  );
};

export const Footer = () => {
  return (
    <div className="tw-px-4 tw-py-10 md:tw-px-16 md:tw-py-16">
      <div
        className="tw-flex tw-items-center tw-gap-2 tw-mb-6 tw-cursor-pointer hover:tw-opacity-80 tw-transition-opacity"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <div className="tw-grow tw-border-t tw-border[#E9ECEF]"></div>
        <div className="tw-text-primary-main tw-text-sm">Back to top</div>
        <ArrowUpIcon />
      </div>

      <div className="md:tw-grid md:tw-grid-cols-12 md:tw-gap-12">
        {/* Logo and Contact Section */}
        <div className="md:tw-col-span-3">
          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-8">
            <SotravelIcon size={34} color="#00C1FD" />
            <div className="tw-text-[25px] tw-text-primary-main tw-font-bold">
              Sotravel
            </div>
          </div>

          <div className="tw-mb-4">
            <div className="tw-text[#212529] tw-text-sm md:tw-text-base tw-font-bold tw-mb-2">
              For inquiry & assistance
            </div>
            <a
              href="https://t.me/sotravel_official"
              target="_blank"
              className="tw-text-primary-7 tw-text-xs md:tw-text-sm tw-block hover:tw-underline"
              rel="noreferrer"
            >
              @sotravel_official
            </a>
          </div>

          <div className="tw-mb-6">
            <div className="tw-text[#212529] tw-text-sm md:tw-text-base tw-font-bold tw-mb-2">
              For collab & partnerships
            </div>
            <a
              href="mailto:hello@sotravel.com"
              className="tw-text-primary-7 tw-text-xs md:tw-text-sm tw-block hover:tw-underline"
            >
              hello@sotravel.com
            </a>
          </div>
        </div>

        {/* Location and Quick Links Section */}
        <div className="md:tw-col-span-3">
          <div className="tw-mb-6">
            <div className="tw-text[#212529] tw-text-sm md:tw-text-base tw-font-bold tw-mb-4">
              Quick links
            </div>
            <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-1 md:tw-gap-y-3">
              <div>
                <Link
                  to="/all-trips"
                  className="tw-text-sm md:tw-text-base tw-mb-2 md:tw-mb-0 tw-text-[#212529] hover:tw-text-primary-main tw-transition-colors"
                >
                  Trips
                </Link>
              </div>
              <div>
                <a
                  href="https://blog.sotravel.com"
                  target="_blank"
                  className="tw-text-sm md:tw-text-base tw-mb-2 md:tw-mb-0 tw-text-[#212529] hover:tw-text-primary-main tw-transition-colors"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/sotravel_sg"
                  target="_blank"
                  className="tw-text-sm md:tw-text-base tw-mb-2 md:tw-mb-0 tw-text-[#212529] hover:tw-text-primary-main tw-transition-colors"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </div>
              <div>
                <a
                  href="https://tiktok.com/@sotravel_sg"
                  target="_blank"
                  className="tw-text-sm md:tw-text-base tw-mb-2 md:tw-mb-0 tw-text-[#212529] hover:tw-text-primary-main tw-transition-colors"
                  rel="noreferrer"
                >
                  TikTok
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="md:tw-col-span-6">
          <div className="tw-relative">
            <img
              alt="newsletter"
              className="tw-rounded-lg tw-w-full tw-h-[201px] md:tw-h-[260px] tw-object-cover"
              src="https://d1puf2nnbcyl9q.cloudfront.net/1724430195075_blob.jpeg"
            />
            <div className="tw-absolute tw-inset-0 tw-bg-[#0000004D]/30 tw-rounded-lg"></div>
            <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6">
              <div className="tw-text-center tw-font-extrabold tw-text-white tw-text-xl md:tw-text-2xl tw-mb-6">
                Join our Telegram community for exclusive deals & updates
              </div>

              <Link
                to="https://t.me/sotravel_sg"
                target="_blank"
                className="tw-block tw-w-full md:tw-w-2/3"
              >
                <button className="tw-bg-primary-main hover:tw-bg-primary-dark tw-text-white tw-rounded-lg tw-px-6 tw-py-3 tw-text-base md:tw-text-lg tw-font-bold tw-w-3/4 md:tw-w-full tw-mx-auto tw-block tw-transition-colors">
                  Join community
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
