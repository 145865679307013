import unauthAxios, { unauthAxiosFetcher } from "../axios";
import authAxios, { authAxiosFetcher } from "../authAxios";
import useSWR from "swr";
import { objectToQueryParams } from "../utils/utils";

export const useTrips = () => useSWR(`/v1/trip/getTrips`, unauthAxiosFetcher);

export const useUserInterestedAndDiscountedTrips = (user_id = "") => {
  if (user_id === null || user_id === undefined) {
    user_id = "";
  }

  return useSWR(
    `/v1/trip/getUserInterestedAndDiscountedTrips?user_id=${user_id}`,
    unauthAxiosFetcher
  );
};

export const useTrendingTrips = () =>
  useSWR(`/v1/trip/getTrendingTrips`, unauthAxiosFetcher);

export const useTrip = (tripId, getFromCache = false) => {
  return useSWR(
    tripId
      ? `/v1/trip/getTrip?trip_id=${tripId}&getFromCache=${getFromCache}`
      : null,
    unauthAxiosFetcher
  );
};

export const getTrip = (tripId, getFromCache = false) =>
  unauthAxios.get("/v1/trip/getTrip", {
    params: { trip_id: tripId, getFromCache: getFromCache },
  });

export const searchTrips = (months, continents, search, user_id) =>
  unauthAxios.get("/v1/trip/searchTrips", {
    params: {
      months: months,
      continents: continents,
      search: search,
      user_id: user_id,
    },
  });

export const getUserTrips = async (userId) => {
  return await unauthAxios.get(`/v1/trip/getUserTrips?user_id=${userId}`);
};

export const getUserPastTrips = async (userId) => {
  const res = await unauthAxios.get(
    `/v1/trip/getUserPastTrips?user_id=${userId}`
  );
  return [
    res.data.pastTrips,
    res.data.hasClimbExperience,
    res.data.hasSkiExperience,
  ];
};

export const createTrip = (projectId, startDate) => {
  return unauthAxios.post("/v1/trip/createCoordinatedTripAdmin", {
    project_id: projectId,
    start_date: startDate,
    age_restriction: "18-40",
    status: "waitlist",
    trip_type: "community",
    earlyBirdPercentage: 100,
    lateBirdPercentage: 0,
    approvedToRun: 0,
  });
};

export const getUsersGoingForTrip = (tripId) =>
  unauthAxios.get(`/v1/trip/${tripId}/users`);

export const giveFeedbackCredits = (tripId) =>
  authAxios.get(`/v1/trip/giveFeedbackCredits?trip_id=${tripId}`);

export const useUsersGoingForTrip = (tripId) =>
  useSWR(`/v1/trip/${tripId}/users`, unauthAxiosFetcher);

export const getUserTrip = (user_id, trip_id) => {
  return unauthAxios.get(
    `/v1/trip/getUserTrip?user_id=${user_id}&trip_id=${trip_id}`
  );
};

export const isUserALocalPartnerOrLeader = (user_id, trip_id) =>
  unauthAxios.get(
    `/v1/trip/isUserALocalPartnerOrLeader?user_id=${user_id}&trip_id=${trip_id}`
  );

// --- Trip Forms & itinerary ----

// data must contain trip_id
export const upsertUserTrip = (data) => {
  authAxios.post("/v1/trip/upsertUserTrip", data);
};

// query can be { showUsers: boolean, isCheckout: boolean }
export const getTripGroupForms = (tripId, query) =>
  unauthAxios.get(
    `/v1/trip/${tripId}/groupForms?${objectToQueryParams(query)}`
  );

export const getTripInfoForm = (tripId) =>
  authAxios.get(`/v1/trip/${tripId}/infoForm`);

// tripForms is an array of tripForm to be updated in the format
// interface tripForm {
// formId,
// selectedGroupId,
// selectedGroupName,
// selectedGroupDescription,
// }
export const updateTripGroupForms = (tripForms) =>
  authAxios.put(`/v1/trip/groupForms`, { tripForms });

export const updateTripInfoFormAnswers = (infoFormId, data) =>
  authAxios.post(`/v1/trip/infoFormAnswers`, data);

export const getTripIndemnityForm = (tripId) => {
  return authAxios.get(`/v1/trip/${tripId}/indemnityForm`);
};

export const isUserOnTrip = (tripId) => {
  return authAxios.get(`/v1/trip/isUserOnTrip?trip_id=${tripId}`);
};

// query can be { showUsers: boolean, isCheckout: boolean }
export const useTripGroupForms = (tripId, query) =>
  useSWR(
    `/v1/trip/${tripId}/groupForms?${objectToQueryParams(query)}`,
    unauthAxiosFetcher
  );

export const verifyTripPayment = (data) => {
  return authAxios.post("/v1/stripe/paymentConfirmation", data);
};

export const upsertTripReview = (review_data) => {
  return authAxios.post("/v1/trip/upsertTripReview", review_data);
};

export const cancelTrip = (cancel_data) => {
  return authAxios.post("/v1/trip/cancelTrip", cancel_data);
};

export const getProjectMemories = async (project_id) => {
  return await unauthAxios.get(
    `/v1/project/getProjectMemories?project_id=${project_id}`
  );
};

export const useSpecificInterestCategories = () => {
  return useSWR(`/v1/trip/getSpecificInterestCategories`, unauthAxiosFetcher);
};

export const addTripMediaEntries = async (tripId, uploadedMediaArray) => {
  const response = await authAxios.put("/v1/mobileapp/addMemoryEntries", {
    tripId,
    mediaArray: uploadedMediaArray,
  });

  return response;
};

export const addTripMediaAdmin = async (projectId, uploadedMediaArray) => {
  const response = await authAxios.put("/v1/admin/addTripMedia", {
    projectId,
    mediaArray: uploadedMediaArray,
  });

  return response;
};

export const incrementWebsiteBannerClickCount = async (bannerId) => {
  unauthAxios.post("/v1/trip/incrementWebsiteBannerClickCount", {
    bannerId,
  });
};
