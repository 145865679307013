import { AnimatePresence, motion } from "framer-motion";
import { useTabletOrMobile } from "../../../hooks/responsive";
import UserProfile from "../../UserProfile";
import { createPortal } from "react-dom";

export default function UserProfileBottomSheet({
  showUserProfile,
  setShowUserProfile,
  userId,
}) {
  const isMobile = useTabletOrMobile();

  return createPortal(
    <AnimatePresence>
      {isMobile && showUserProfile && (
        <motion.div
          className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-[100000]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setShowUserProfile(false)}
        >
          <motion.div
            className="tw-fixed tw-inset-0 tw-overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <motion.div
              className="tw-min-h-full tw-bg-white tw-relative"
              initial={{ x: "100%" }}
              animate={{
                x: 0,
                transition: {
                  type: "spring",
                  damping: 25,
                  stiffness: 300,
                },
              }}
              exit={{
                x: "100%",
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 300,
                },
              }}
            >
              <motion.button
                onClick={() => setShowUserProfile(false)}
                className="tw-absolute tw-right-4 tw-top-4 tw-z-10"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-6 tw-w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </motion.button>
              <UserProfile passing_user_id={userId} />
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
}
