import { AnimatePresence, motion } from "framer-motion";
import {
  Camera,
  CheckCircle2,
  ChevronDown,
  ChevronRight,
  Heart,
  Loader,
  MapPin,
  PartyPopper,
  Search,
  Users,
  X,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import authAxios from "../../authAxios";
import instance from "../../axios";
import { useGlobalState } from "../../GlobalStates";
import { Footer, StickyHeader } from "../../intro/components/landing-new";

// Travel categories enum
const TRAVEL_CATEGORIES = [
  { value: "hiking", label: "Hiking & Trekking", icon: "🏃‍♂️" },
  { value: "beach", label: "Beach & Surf", icon: "🏄‍♂️" },
  { value: "snow", label: "Snow & Ski", icon: "⛷️" },
  { value: "culture", label: "Culture & Heritage", icon: "🏛️" },
  { value: "food", label: "Food & Culinary", icon: "🍜" },
  { value: "adventure", label: "Adventure Sports", icon: "🧗‍♀️" },
  { value: "wildlife", label: "Wildlife & Nature", icon: "🦁" },
  { value: "urban", label: "Urban Exploration", icon: "🌆" },
  { value: "wellness", label: "Wellness & Spa", icon: "💆‍♀️" },
  { value: "photography", label: "Photography Tours", icon: "📸" },
];

const CategorySelect = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const selectedCategory = TRAVEL_CATEGORIES.find(
    (cat) => cat.value === value
  ) || {
    icon: "🌎",
    label: "Select a category",
    value: "",
  };

  const handleCategoryClick = (categoryValue) => {
    // If clicking the already selected category, unselect it
    if (value === categoryValue) {
      onChange("");
    } else {
      onChange(categoryValue);
    }
    setIsOpen(false);
  };

  return (
    <div className="tw-relative" ref={dropdownRef}>
      <motion.div
        whileTap={{ scale: 0.98 }}
        onClick={() => setIsOpen(!isOpen)}
        className="tw-mt-2 tw-w-full tw-flex tw-items-center tw-justify-between tw-rounded-xl tw-border-0 tw-bg-gray-50 tw-px-4 tw-py-3.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-200 focus:tw-ring-2 focus:tw-ring-[deepskyblue] tw-transition-all tw-cursor-pointer"
      >
        <div className="tw-flex tw-items-center tw-space-x-2">
          <span className="tw-text-xl">{selectedCategory.icon}</span>
          <span>{selectedCategory.label}</span>
        </div>
        <ChevronDown
          className={`tw-w-5 tw-h-5 tw-text-gray-400 tw-transition-transform ${
            isOpen ? "tw-rotate-180" : ""
          }`}
        />
      </motion.div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="tw-absolute tw-z-10 tw-mt-2 tw-w-full tw-rounded-xl tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden"
          >
            <div className="tw-max-h-60 tw-overflow-y-auto">
              {TRAVEL_CATEGORIES.map((category) => (
                <motion.div
                  key={category.value}
                  onClick={() => handleCategoryClick(category.value)}
                  className={`tw-w-full tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-3 tw-text-left tw-cursor-pointer hover:tw-text-[deepskyblue] hover:tw-bg-sky-50 tw-transition-colors ${
                    value === category.value
                      ? "tw-bg-sky-50 tw-text-[deepskyblue]"
                      : "tw-text-gray-700"
                  }`}
                >
                  <span className="tw-text-xl">{category.icon}</span>
                  <span>{category.label}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Toast = ({ message, type, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="tw-fixed tw-bottom-4 tw-right-4 tw-z-50"
    >
      <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-4 tw-flex tw-items-center tw-space-x-3">
        {type === "success" && (
          <CheckCircle2 className="tw-w-5 tw-h-5 tw-text-emerald-500" />
        )}
        {type === "celebration" && (
          <PartyPopper className="tw-w-5 tw-h-5 tw-text-amber-500" />
        )}
        <p className="tw-text-gray-700">{message}</p>
        <button
          onClick={onClose}
          className="tw-p-1 hover:tw-bg-gray-100 tw-rounded-full"
        >
          <X className="tw-w-4 tw-h-4 tw-text-gray-500" />
        </button>
      </div>
    </motion.div>
  );
};

const VoterAvatars = ({ voters, maxDisplay = 5 }) => {
  const displayedVoters = voters.slice(0, maxDisplay);
  const remainingCount = voters.length - maxDisplay;

  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-flex -tw-space-x-2">
        {displayedVoters.map((voter, index) => (
          <div
            key={index}
            className="tw-w-8 tw-h-8 tw-rounded-full tw-border-2 tw-border-white tw-overflow-hidden tw-relative tw-group"
          >
            <img
              src={voter.avatar_url}
              alt={voter.first_name}
              className="tw-w-full tw-h-full tw-object-cover"
            />
            <div className="tw-absolute tw-invisible tw-group-hover:tw-visible tw-bg-black tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-xs tw-whitespace-nowrap tw-bottom-full tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-2">
              {voter.first_name}
            </div>
          </div>
        ))}
        {remainingCount > 0 && (
          <div className="tw-w-8 tw-h-8 tw-rounded-full tw-bg-gray-100 tw-border-2 tw-border-white tw-flex tw-items-center tw-justify-center">
            <span className="tw-text-xs tw-font-medium tw-text-gray-600">
              +{remainingCount}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const Ideas = () => {
  const [ideas, setIdeas] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [toast, setToast] = useState(null);
  const fileInputRef = useRef(null);
  const [votedIdeas, setVotedIdeas] = useState(new Set());
  const [newIdea, setNewIdea] = useState({
    destination: "",
    description: "",
    category: "",
    images: [],
    imagePreviews: [],
  });

  const [user] = useGlobalState("user");

  useEffect(() => {
    fetchIdeas();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewIdea((prev) => ({
          ...prev,
          images: [...prev.images, file],
          imagePreviews: [...prev.imagePreviews, reader.result],
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index) => {
    setNewIdea((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
      imagePreviews: prev.imagePreviews.filter((_, i) => i !== index),
    }));
  };

  const fetchIdeas = async () => {
    try {
      const response = await instance.get("/v1/ideas", {
        params: {
          userId: user.id,
        },
      });
      setIdeas(response.data);
      if (response.data.length > 0 && selectedIdea) {
        setSelectedIdea(
          response.data.find((idea) => idea.id === selectedIdea.id)
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching ideas:", error);
      setIsLoading(false);
    }
  };

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 4000);
  };

  const handleVote = async (id) => {
    if (votedIdeas.has(id)) {
      return; // Early return if already voted
    }

    try {
      await authAxios.post(`/v1/ideas/${id}/vote`, {
        increment: true,
        userId: user.id,
      });
      setVotedIdeas((prev) => new Set([...prev, id])); // Add to voted set
      await fetchIdeas();
      showToast(
        "Thanks for your vote! We'll consider popular destinations for future trips.",
        "success"
      );
    } catch (error) {
      showToast(
        error.response.data.error || "Failed to vote. Please try again.",
        "error"
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    try {
      const formData = new FormData();
      formData.append("destination", newIdea.destination);
      formData.append("description", newIdea.description);
      if (newIdea.category) {
        formData.append("category", newIdea.category);
      }
      newIdea.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      if (user.id) {
        formData.append("userId", user.id);
      }

      await authAxios.post("/v1/ideas", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setShowForm(false);
      setNewIdea({
        destination: "",
        description: "",
        category: "",
        images: [],
        imagePreviews: [],
      });

      showToast(
        "Your travel idea has been shared! Our team will review it for potential future Sotravel trips. Keep the ideas coming!",
        "celebration"
      );

      await fetchIdeas();
    } catch (error) {
      console.error("Error submitting idea:", error);
      showToast("Failed to submit idea. Please try again.", "error");
    } finally {
      setLoadingSubmit(false);
    }
  };

  const filteredIdeas = ideas.filter(
    (idea) =>
      (idea.destination.toLowerCase().includes(searchQuery.toLowerCase()) ||
        idea.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (!selectedCategory || idea.category === selectedCategory)
  );

  return (
    <div className="tw-min-h-screen tw-bg-gradient-to-br tw-from-sky-50 tw-via-white tw-to-blue-50 tw-pt-24 tw-pb-10 tw-px-4 sm:tw-px-6 lg:tw-px-8">
      <StickyHeader withoutCta />
      <div className="tw-max-w-4xl tw-mx-auto tw-min-h-[100vh]">
        <div className="tw-text-center">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="tw-text-4xl tw-font-bold tw-bg-gradient-to-r tw-from-[deepskyblue] tw-to-blue-600 tw-bg-clip-text tw-text-transparent tw-mb-8"
          >
            Travel Ideas & Destinations
          </motion.h1>

          <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-gap-4 tw-mb-8">
            <button
              onClick={() => setShowForm(!showForm)}
              className="tw-bg-gradient-to-r tw-from-[deepskyblue] tw-to-blue-500 tw-text-white tw-px-8 tw-py-4 tw-rounded-full tw-shadow-lg tw-font-semibold hover:tw-shadow-xl tw-transition-all"
            >
              Share your travel idea
            </button>

            <div className="tw-relative tw-w-full sm:tw-w-96">
              <Search className="tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2 tw-text-gray-400 tw-w-5 tw-h-5" />
              <input
                type="text"
                placeholder="Search existing travel ideas..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="tw-w-full tw-pl-10 tw-pr-4 tw-py-4 tw-rounded-full tw-border-0 tw-bg-white tw-shadow focus:tw-ring-2 focus:tw-ring-[deepskyblue] tw-transition-all"
              />
            </div>
          </div>

          <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 tw-mb-12">
            <button
              onClick={() => setSelectedCategory("")}
              className={`tw-px-4 tw-py-2 tw-rounded-full tw-text-sm tw-font-medium tw-transition-all
                ${
                  !selectedCategory
                    ? "tw-bg-[deepskyblue] tw-text-white"
                    : "tw-bg-white tw-text-gray-600 hover:tw-bg-gray-50"
                }`}
            >
              All Categories
            </button>
            {TRAVEL_CATEGORIES.map((category) => (
              <button
                key={category.value}
                onClick={() => setSelectedCategory(category.value)}
                className={`tw-px-4 tw-py-2 tw-rounded-full tw-text-sm tw-font-medium tw-transition-all tw-flex tw-items-center tw-space-x-2
                  ${
                    selectedCategory === category.value
                      ? "tw-bg-[deepskyblue] tw-text-white"
                      : "tw-bg-white tw-text-gray-600 hover:tw-bg-gray-50"
                  }`}
              >
                <span>{category.icon}</span>
                <span>{category.label}</span>
              </button>
            ))}
          </div>
        </div>

        <AnimatePresence mode="wait" initial={false}>
          {showForm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="tw-fixed tw-inset-0 tw-bg-black/50 tw-backdrop-blur-sm tw-z-50 tw-flex tw-items-center tw-justify-center tw-p-4"
            >
              <motion.div
                initial={{ scale: 0.9, y: 50 }}
                animate={{ scale: 1, y: 0 }}
                exit={{ scale: 0.9, y: 50 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 25,
                }}
                className="tw-bg-white tw-rounded-3xl tw-shadow-2xl tw-w-full tw-max-w-2xl tw-max-h-[90vh] tw-overflow-auto"
              >
                <div className="tw-p-8">
                  <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
                    <div>
                      <motion.h2
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2, duration: 0.4 }}
                        className="tw-text-2xl tw-font-bold tw-bg-gradient-to-r tw-from-gray-900 tw-to-gray-600 tw-bg-clip-text tw-text-transparent"
                      >
                        Share your dream adventure
                      </motion.h2>
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3, duration: 0.4 }}
                        className="tw-text-gray-500 tw-mt-2"
                      >
                        Your ideas could shape future Sotravel trips! We
                        carefully review all suggestions.
                      </motion.p>
                    </div>
                    <motion.button
                      whileHover={{ rotate: 90 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => setShowForm(false)}
                      className="tw-p-2 tw-rounded-full hover:tw-bg-gray-100"
                    >
                      <X className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    </motion.button>
                  </div>

                  <form onSubmit={handleSubmit} className="tw-space-y-8">
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2, duration: 0.3 }}
                    >
                      <label className="tw-block tw-text-base tw-font-medium tw-bg-gradient-to-r tw-from-gray-800 tw-to-gray-600 tw-bg-clip-text tw-text-transparent">
                        Where would you like to go?
                      </label>
                      <motion.input
                        whileFocus={{ scale: 1.01 }}
                        type="text"
                        required
                        placeholder="e.g. Bali, Indonesia"
                        className="tw-mt-2 tw-block tw-w-full tw-rounded-xl tw-border-0 tw-bg-gray-50 tw-px-4 tw-py-3.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-200 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-[deepskyblue] tw-transition-all"
                        value={newIdea.destination}
                        onChange={(e) =>
                          setNewIdea({
                            ...newIdea,
                            destination: e.target.value,
                          })
                        }
                      />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3, duration: 0.3 }}
                    >
                      <label className="tw-block tw-text-base tw-font-medium tw-bg-gradient-to-r tw-from-gray-800 tw-to-gray-600 tw-bg-clip-text tw-text-transparent">
                        Category
                      </label>
                      <CategorySelect
                        value={newIdea.category}
                        onChange={(value) =>
                          setNewIdea({ ...newIdea, category: value })
                        }
                      />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4, duration: 0.3 }}
                    >
                      <label className="tw-block tw-text-base tw-font-medium tw-bg-gradient-to-r tw-from-gray-800 tw-to-gray-600 tw-bg-clip-text tw-text-transparent">
                        Tell us about your dream trip
                      </label>
                      <motion.textarea
                        whileFocus={{ scale: 1.01 }}
                        rows={6}
                        placeholder="Share your vision for this adventure... What activities would you like to do? What experiences are you looking forward to? Any specific places you'd like to visit?"
                        className="tw-mt-2 tw-block tw-w-full tw-rounded-xl tw-border-0 tw-bg-gray-50 tw-px-4 tw-py-3.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-200 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-[deepskyblue] tw-transition-all"
                        value={newIdea.description}
                        onChange={(e) =>
                          setNewIdea({
                            ...newIdea,
                            description: e.target.value,
                          })
                        }
                      />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.5, duration: 0.3 }}
                      className="tw-space-y-4"
                    >
                      <label className="tw-block tw-text-base tw-font-medium tw-bg-gradient-to-r tw-from-gray-800 tw-to-gray-600 tw-bg-clip-text tw-text-transparent">
                        Add photos
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        className="tw-hidden"
                      />
                      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        {newIdea.imagePreviews.map((preview, index) => (
                          <div key={index} className="tw-relative">
                            <img
                              src={preview}
                              alt={`Preview ${index + 1}`}
                              className="tw-w-full tw-h-48 tw-object-cover tw-rounded-xl"
                            />
                            <button
                              type="button"
                              onClick={() => handleRemoveImage(index)}
                              className="tw-absolute tw-top-2 tw-right-2 tw-p-1 tw-bg-white tw-rounded-full hover:tw-bg-gray-100"
                            >
                              <X className="tw-w-4 tw-h-4 tw-text-gray-500" />
                            </button>
                          </div>
                        ))}
                        <div
                          onClick={() => fileInputRef.current?.click()}
                          className="tw-cursor-pointer tw-relative tw-w-full tw-h-48 tw-rounded-xl tw-bg-gray-50 tw-border-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-justify-center hover:tw-bg-gray-100 tw-transition-colors"
                        >
                          <div className="tw-text-center">
                            <Camera className="tw-w-8 tw-h-8 tw-text-gray-400 tw-mx-auto tw-mb-2" />
                            <p className="tw-text-sm tw-text-gray-500">
                              Click to upload photos
                            </p>
                          </div>
                        </div>
                      </div>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6, duration: 0.3 }}
                      className="tw-flex tw-justify-end tw-space-x-4 tw-pt-4"
                    >
                      <motion.button
                        whileHover={
                          !loadingSubmit
                            ? {
                                scale: 1.05,
                                boxShadow: "0 15px 30px rgba(0,0,0,0.15)",
                              }
                            : {}
                        }
                        whileTap={!loadingSubmit ? { scale: 0.95 } : {}}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 15,
                        }}
                        type="submit"
                        disabled={loadingSubmit}
                        className="tw-px-8 tw-py-3.5 tw-bg-gradient-to-r tw-from-[deepskyblue] tw-to-blue-500 tw-text-white tw-rounded-xl tw-text-base tw-font-medium tw-shadow-lg hover:tw-shadow-xl tw-transition-all disabled:tw-opacity-50 disabled:tw-cursor-not-allowed tw-flex tw-items-center tw-space-x-2"
                      >
                        {loadingSubmit ? (
                          <>
                            <Loader className="tw-w-5 tw-h-5 tw-animate-spin" />
                            <span>Sharing...</span>
                          </>
                        ) : (
                          <span>Share adventure</span>
                        )}
                      </motion.button>
                    </motion.div>
                  </form>
                </div>
              </motion.div>
            </motion.div>
          )}

          {selectedIdea && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="tw-fixed tw-inset-0 tw-bg-black/50 tw-backdrop-blur-sm tw-z-50 tw-flex tw-items-center tw-justify-center tw-p-4"
              style={{ overflowY: "hidden" }}
            >
              <motion.div
                initial={{ scale: 0.9, y: 50 }}
                animate={{ scale: 1, y: 0 }}
                exit={{ scale: 0.9, y: 50 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 25,
                }}
                className="tw-bg-white tw-rounded-3xl tw-shadow-2xl tw-w-full tw-max-w-2xl tw-my-8 tw-max-h-[90vh] tw-overflow-hidden tw-flex tw-flex-col"
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="tw-p-8 tw-overflow-y-auto"
                >
                  <div className="tw-flex tw-justify-between tw-items-start tw-mb-6">
                    <div>
                      <motion.h2
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.4 }}
                        className="tw-text-2xl tw-font-bold tw-bg-gradient-to-r tw-from-gray-900 tw-to-gray-600 tw-bg-clip-text tw-text-transparent"
                      >
                        {selectedIdea.destination}
                      </motion.h2>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        className="tw-flex tw-items-center tw-space-x-2 tw-mt-2"
                      >
                        <span className="tw-text-sm tw-text-gray-500">
                          Posted by {selectedIdea.author || "Anonymous"}
                        </span>
                        <span className="tw-text-gray-300">•</span>
                        <span className="tw-px-3 tw-py-1 tw-rounded-full tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-600">
                          {TRAVEL_CATEGORIES.find(
                            (cat) => cat.value === selectedIdea.category
                          )?.label || "Uncategorized"}
                        </span>
                      </motion.div>
                    </div>
                    <motion.button
                      whileHover={{ rotate: 90 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => setSelectedIdea(null)}
                      className="tw-p-2 tw-rounded-full hover:tw-bg-gray-100"
                    >
                      <X className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    </motion.button>
                  </div>

                  {selectedIdea.images && selectedIdea.images.length > 0 && (
                    <div className="tw-mb-6 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      {selectedIdea.images.map((image, index) => (
                        <div
                          key={index}
                          className="tw-rounded-xl tw-overflow-hidden tw-h-64"
                        >
                          <img
                            src={image}
                            alt={`${selectedIdea.destination} - Image ${
                              index + 1
                            }`}
                            className="tw-w-full tw-h-full tw-object-cover"
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 0.4 }}
                    className="tw-mb-8 tw-pr-2"
                  >
                    {selectedIdea.description ? (
                      <p className="tw-text-gray-600 tw-whitespace-pre-line">
                        {selectedIdea.description}
                      </p>
                    ) : (
                      <p className="tw-text-gray-400 tw-italic">
                        No description provided
                      </p>
                    )}
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.4 }}
                    className="tw-flex tw-flex-col tw-space-y-4"
                  >
                    <div className="tw-flex tw-justify-between tw-items-center">
                      <div className="tw-flex tw-items-center tw-space-x-4">
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleVote(selectedIdea.id)}
                          className="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-2 tw-rounded-full tw-bg-rose-50 hover:tw-bg-rose-100 tw-transition-colors"
                        >
                          <Heart
                            className={`tw-w-5 tw-h-5 ${
                              selectedIdea.hasVoted
                                ? "tw-fill-rose-500 tw-text-rose-500"
                                : "tw-text-rose-500"
                            }`}
                          />
                          <span className="tw-font-medium tw-text-rose-500">
                            {selectedIdea.votes} votes
                          </span>
                        </motion.button>
                      </div>
                    </div>

                    {selectedIdea.voters && selectedIdea.voters.length > 0 && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.7 }}
                        className="tw-bg-gray-50 tw-rounded-xl tw-p-4"
                      >
                        <div className="tw-flex tw-items-center tw-space-x-3 tw-mb-3">
                          <Users className="tw-w-5 tw-h-5 tw-text-gray-500" />
                          <span className="tw-font-medium tw-text-gray-700">
                            Supporters
                          </span>
                        </div>
                        <VoterAvatars
                          voters={selectedIdea.voters}
                          maxDisplay={8}
                        />
                        {selectedIdea.voters.length > 8 && (
                          <p className="tw-text-sm tw-text-gray-500 tw-mt-2">
                            and {selectedIdea.voters.length - 8} more supporters
                          </p>
                        )}
                      </motion.div>
                    )}
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}

          {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
            />
          )}
        </AnimatePresence>

        <div className="tw-space-y-4">
          {isLoading ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-12">
              <motion.div
                animate={{
                  rotate: 360,
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <Loader className="tw-w-8 tw-h-8 tw-text-[deepskyblue]" />
              </motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="tw-mt-4 tw-text-gray-500"
              >
                Loading adventures...
              </motion.p>
            </div>
          ) : filteredIdeas.length === 0 ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="tw-text-center tw-py-12"
            >
              <motion.div
                animate={{
                  y: [0, -10, 0],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <MapPin className="tw-w-16 tw-h-16 tw-text-gray-300 tw-mx-auto tw-mb-4" />
              </motion.div>
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="tw-text-xl tw-font-semibold tw-text-gray-900 tw-mb-2"
              >
                No adventures found
              </motion.h3>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="tw-text-gray-500"
              >
                Try adjusting your search or share your own travel idea!
              </motion.p>
            </motion.div>
          ) : (
            <AnimatePresence mode="popLayout" initial={false}>
              {filteredIdeas
                .sort((a, b) => b.votes - a.votes)
                .map((idea, index) => (
                  <motion.div
                    layout="position"
                    key={idea.id}
                    initial={{ opacity: 0, scale: 0.95, y: 20 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.95, y: -20 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.3 },
                      layout: { duration: 0.3 },
                      delay: index * 0.1,
                    }}
                    className="tw-bg-white tw-rounded-xl tw-shadow-sm hover:tw-shadow-md tw-transition-shadow tw-p-4 tw-flex tw-items-center tw-justify-between tw-group"
                  >
                    <div className="tw-flex tw-items-center tw-space-x-4 tw-grow">
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        className="tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer"
                        onClick={() => handleVote(idea.id, true)}
                      >
                        <Heart className="tw-w-5 tw-h-5 tw-text-[deepskyblue]" />
                        <motion.span
                          layout="position"
                          className="tw-font-medium"
                          transition={{ duration: 0.3 }}
                        >
                          {idea.votes}
                        </motion.span>
                        {/* <div className="tw-flex tw-space-x-1">
                          <motion.button
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400 }}
                            className="tw-p-1 tw-rounded-full hover:tw-bg-sky-50"
                          >
                            <ArrowUp className="tw-w-4 tw-h-4 tw-text-[deepskyblue]" />
                          </motion.button>
                        </div> */}
                      </motion.div>
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="tw-flex tw-items-center tw-space-x-2"
                      >
                        <MapPin className="tw-w-5 tw-h-5 tw-text-gray-400" />
                        <span className="tw-font-medium">
                          {idea.destination}
                        </span>
                      </motion.div>
                    </div>

                    <motion.button
                      whileHover={{ x: 8, scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => setSelectedIdea(idea)}
                      className="tw-p-2 tw-rounded-full hover:tw-bg-sky-50 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity"
                    >
                      <ChevronRight className="tw-w-5 tw-h-5 tw-text-[deepskyblue]" />
                    </motion.button>
                  </motion.div>
                ))}
            </AnimatePresence>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Ideas;
