import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import authAxios from "../../authAxios";
import { useIsMobile } from "../../hooks/responsive";
import UserProfileBottomSheet from "./components/UserProfileBottomSheet";
import { createPortal } from "react-dom";
import instance from "../../axios";
import { useGlobalState } from "../../GlobalStates";
import { CircularProgress } from "@mui/material";

const Connections = () => {
  const [user] = useGlobalState("user");
  const [userLoading] = useGlobalState("userLoading");

  if (userLoading) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!user.id) {
    return <Navigate to="/" />;
  }

  return <_Connections />;
};

const _Connections = () => {
  const { user_id: _user_id } = useParams();
  const [user] = useGlobalState("user");

  const isOtherUserProfile = !!_user_id;
  const user_id = isOtherUserProfile ? _user_id : user.id;
  const currentUserId = user.id;

  const [activeTab, setActiveTab] = useState("following");
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); // Add search results state
  const [selectedUser, setSelectedUser] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [loadingRecommendations, setLoadingRecommendations] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showConfirmationSheet, setShowConfirmationSheet] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const popoverRef = useRef(null);
  const isMobile = useIsMobile();

  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");

  const navigate = useNavigate();

  useEffect(() => {
    // Listen for contactsImported event from React Native
    const handleMessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.event === "contactsImported") {
          const { emails, phoneNumbers } = message.data;
          navigate("/profile/connections/import-contacts", {
            state: { emails, phoneNumbers },
          });
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [navigate]);

  const handleImportContacts = () => {
    // Check if the WebView bridge is available
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("importContacts");
    } else {
      console.error("ReactNativeWebView bridge is not available");
    }
  };

  const confirmRemoveSuggestion = async () => {
    if (!userToRemove) return;

    // Store original recommendations for rollback
    const originalRecommendations = [...recommendations];

    // Optimistically remove from recommendations
    setRecommendations((prev) =>
      prev.filter((rec) => rec.id !== userToRemove.id)
    );
    setShowConfirmDialog(false);
    setUserToRemove(null);

    try {
      // Make API call
      await authAxios.post(`/v1/social/blockRecommendation/${userToRemove.id}`);
      setToastMessage("Suggestion removed successfully");
      setToastType("success");
      setTimeout(() => {
        setToastMessage("");
        setToastType("");
      }, 3000);
    } catch (error) {
      console.error("Failed to remove suggestion:", error);
      // Revert optimistic update on error
      setRecommendations(originalRecommendations);
      setToastMessage("Failed to remove suggestion. Please try again.");
      setToastType("error");
      setTimeout(() => {
        setToastMessage("");
        setToastType("");
      }, 3000);
    }
  };

  const MobileBottomSheet = ({ user, onClose }) => {
    const name = [user.first_name, user.last_name].filter(Boolean).join(" ");

    const handleHideActivity = async () => {
      setShowConfirmationSheet(true);
    };

    return (
      <AnimatePresence>
        {showBottomSheet && (
          <>
            <motion.div
              className="tw-fixed tw-inset-0 tw-bg-black/30 tw-backdrop-blur-sm tw-z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={onClose}
            />
            <motion.div
              className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-rounded-t-3xl tw-z-50 tw-overflow-hidden"
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "tween" }}
            >
              <div className="tw-flex tw-flex-col">
                {/* Close button */}
                <button
                  onClick={onClose}
                  className="tw-absolute tw-top-4 tw-right-4 tw-p-2 tw-rounded-full md:hover:tw-bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="tw-h-6 tw-w-6 tw-text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* Header with user info */}
                <div className="tw-px-6 tw-pt-6 tw-bg-gradient-to-b tw-from-gray-50">
                  <div className="tw-flex tw-items-center tw-gap-4">
                    <img
                      src={user.avatar}
                      alt={user.name}
                      className="tw-w-16 tw-h-16 tw-rounded-2xl tw-object-cover tw-shadow-lg"
                    />
                    <div className="tw-flex-1">
                      <h3 className="tw-font-bold tw-text-xl">{name}</h3>
                      <p className="tw-text-gray-500 tw-text-sm">
                        {user.username}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Action buttons */}
                <div className="tw-p-4 tw-space-y-2">
                  <button
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      try {
                        await navigator.share({
                          title: name,
                          text: `Check out ${name}'s profile on SoTravel`,
                          url: `${window.location.origin}/profile/${user.id}`,
                        });
                      } catch (err) {
                        console.log("Error sharing:", err);
                      }
                      onClose();
                    }}
                    className="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-xl md:hover:tw-bg-gray-50"
                  >
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <span>Share Profile</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="tw-h-5 tw-w-5 tw-text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {activeTab === "followers" &&
                    followingHash[user.id]?.is_approved && (
                      <button
                        onClick={handleHideActivity}
                        className="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-xl md:hover:tw-bg-gray-50"
                      >
                        <div className="tw-flex tw-items-center tw-gap-3">
                          <span>
                            {followersHash[user.id]?.hide_activity
                              ? "Show Activity"
                              : "Hide Activity"}
                          </span>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    )}
                </div>
              </div>
            </motion.div>
          </>
        )}

        {showConfirmationSheet && (
          <>
            <motion.div
              className="tw-fixed tw-inset-0 tw-bg-black/30 tw-backdrop-blur-sm tw-z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setShowConfirmationSheet(false)}
            />
            <motion.div
              className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-rounded-t-3xl tw-z-50 tw-overflow-hidden"
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "tween" }}
            >
              <div className="tw-p-6 tw-space-y-6">
                <h3 className="tw-text-xl tw-font-semibold">
                  Hide activity from follower?
                </h3>
                <p className="tw-text-gray-600">
                  We won't tell {user.username} that they were removed from your
                  activity updates
                </p>
                <div className="tw-flex tw-flex-col tw-gap-3">
                  <button
                    onClick={async () => {
                      try {
                        const isHidden = followersHash[user.id]?.hide_activity;
                        // Optimistically update UI
                        const updatedFollowers = followers.map((f) => {
                          if (f.follower_id === user.id) {
                            return { ...f, hide_activity: !isHidden };
                          }
                          return f;
                        });

                        setAuthFollowers(updatedFollowers);

                        // Make API call
                        if (!isHidden) {
                          await authAxios.post(
                            `/v1/social/hideActivity1on1/${user.id}`
                          );
                        } else {
                          await authAxios.post(
                            `/v1/social/showActivity1on1/${user.id}`
                          );
                        }
                        setShowConfirmationSheet(false);
                        onClose();
                      } catch (err) {
                        console.error(
                          "Error toggling activity visibility:",
                          err
                        );
                        // Revert optimistic update on error
                        const revertedFollowers = followers.map((f) => {
                          if (f.follower_id === user.id) {
                            return {
                              ...f,
                              hide_activity:
                                followersHash[user.id]?.hide_activity,
                            };
                          }
                          return f;
                        });
                        setAuthFollowers(revertedFollowers);
                      }
                    }}
                    className="tw-w-full tw-py-3 tw-bg-[deepskyblue] tw-text-white tw-rounded-xl tw-font-medium"
                  >
                    Hide Activity
                  </button>
                  <button
                    onClick={() => setShowConfirmationSheet(false)}
                    className="tw-w-full tw-py-3 tw-bg-gray-100 tw-text-gray-900 tw-rounded-xl tw-font-medium"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    );
  };

  const DesktopPopover = ({ user, onClose }) => {
    const name = [user.first_name, user.last_name].filter(Boolean).join(" ");

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    return (
      <AnimatePresence>
        {showPopover && (
          <>
            <motion.div
              ref={popoverRef}
              className="tw-absolute tw-z-50 tw-bg-white tw-rounded-xl tw-shadow-xl tw-w-64"
              style={{
                top: "100%",
                right: 0,
                marginTop: "8px",
              }}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.1 }}
            >
              <div className="tw-p-3 tw-space-y-1">
                {/* <button
                  onClick={() => {
                    console.log("Opening chat with:", name);
                    onClose();
                  }}
                  className="tw-w-full tw-flex tw-items-center tw-gap-3 tw-p-2 tw-rounded-lg md:hover:tw-bg-gray-50 tw-text-left"
                >
                  <span className="tw-text-gray-600">💬</span>
                  <span>Send Message</span>
                </button> */}
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    try {
                      await navigator.share({
                        title: name,
                        text: `Check out ${name}'s profile on SoTravel`,
                        url: `${window.location.origin}/profile/${user.id}`,
                      });
                    } catch (err) {
                      console.log("Error sharing:", err);
                    }
                    onClose();
                  }}
                  className="tw-w-full tw-flex tw-items-center tw-gap-3 tw-p-2 tw-rounded-lg md:hover:tw-bg-gray-50 tw-text-left"
                >
                  {/* <span className="tw-text-gray-600">📤</span> */}
                  <span>Share Profile</span>
                </button>
                {activeTab === "followers" &&
                  followingHash[user.id]?.is_approved && (
                    <button
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        try {
                          const isHidden =
                            followersHash[user.id]?.hide_activity;
                          // Optimistically update UI
                          const updatedFollowers = followers.map((f) => {
                            if (f.follower_id === user.id) {
                              return { ...f, hide_activity: !isHidden };
                            }
                            return f;
                          });

                          setAuthFollowers(updatedFollowers);

                          // Make API call
                          if (!isHidden) {
                            await authAxios.post(
                              `/v1/social/hideActivity1on1/${user.id}`
                            );
                          } else {
                            await authAxios.post(
                              `/v1/social/showActivity1on1/${user.id}`
                            );
                          }
                        } catch (err) {
                          console.error(
                            "Error toggling activity visibility:",
                            err
                          );
                          // Revert optimistic update on error
                          const revertedFollowers = followers.map((f) => {
                            if (f.follower_id === user.id) {
                              return {
                                ...f,
                                hide_activity:
                                  followersHash[user.id]?.hide_activity,
                              };
                            }
                            return f;
                          });
                          setAuthFollowers(revertedFollowers);
                        }
                      }}
                      className="tw-w-full tw-flex tw-items-center tw-gap-3 tw-p-2 tw-rounded-lg md:hover:tw-bg-gray-50 tw-text-left"
                    >
                      <span>
                        {followersHash[user.id]?.hide_activity
                          ? "Show Activity"
                          : "Hide Activity"}
                      </span>
                    </button>
                  )}
                {/* <div className="tw-h-px tw-bg-gray-200 tw-my-1" />
                <button
                  onClick={() => {
                    if (
                      window.confirm(`Are you sure you want to block ${name}?`)
                    ) {
                      console.log("Blocking user:", name);
                    }
                    onClose();
                  }}
                  className="tw-w-full tw-flex tw-items-center tw-gap-3 tw-p-2 tw-rounded-lg md:hover:tw-bg-red-50 tw-text-red-500 tw-text-left"
                >
                  <span>🚫</span>
                  <span>Block User</span>
                </button> */}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    );
  };

  const UserCard = (user) => {
    const {
      created_at,
      avatar,
      first_name,
      last_name,
      tele_username,
      username,
      followingHash,
      followersHash,
      id,
      mode,
      mutual_connections,
      recommendation_type,
    } = user;

    const name = [first_name, last_name].filter(Boolean).join(" ");
    const [isHovered, setIsHovered] = useState(false);
    const [imageError, setImageError] = useState(false);
    const menuButtonRef = useRef(null);
    const [showUserProfile, setShowUserProfile] = useState(false);

    // Helper function to determine if we are following this user
    const isFollowing = followingHash && followingHash.is_approved;
    const isPendingApproval = followingHash && !followingHash.is_approved;
    const isPrivateUser = followersHash?.is_private;
    const isApproved = followersHash && followersHash.is_approved;

    const getButtonText = () => {
      if (isPendingApproval) {
        return isMobile
          ? "Cancel request"
          : isHovered
          ? "Cancel request"
          : "Requested";
      }

      if (isFollowing) {
        return isMobile ? "Unfollow" : isHovered ? "Unfollow" : "Following";
      }

      if (followersHash && !isApproved) {
        return "Accept request";
      }

      if (followersHash && !followingHash) {
        return isPrivateUser ? "Request follow" : "Follow back";
      }

      return "Follow";
    };

    const getButtonStyle = () => {
      if (!isApproved || isPendingApproval) {
        return "tw-bg-sky-50 tw-text-sky-600 md:hover:tw-bg-sky-100";
      }

      if (isFollowing) {
        return !isMobile && isHovered
          ? "tw-bg-sky-100 tw-text-sky-700 md:hover:tw-bg-sky-200"
          : "tw-bg-sky-50 tw-text-sky-600 md:hover:tw-bg-sky-100";
      }

      return "tw-bg-[deepskyblue] tw-text-white md:hover:tw-bg-sky-500 tw-shadow-sm md:hover:tw-shadow-sky-200/50";
    };

    const handleClick = async (e) => {
      e.stopPropagation();
      e.preventDefault();

      try {
        if (isFollowing) {
          setAuthFollowing((prev) => prev.filter((f) => f.following_id !== id));
          await authAxios.post(`/v1/social/unfollowUser/${id}`);
          return;
        }

        if (isPendingApproval) {
          setAuthFollowing((prev) => prev.filter((f) => f.following_id !== id));
          await authAxios.post(`/v1/social/cancelFollowRequest/${id}`);
          return;
        }

        if (followersHash && !isApproved) {
          setAuthFollowers((prev) =>
            prev.map((f) =>
              f.follower_id === id ? { ...f, is_approved: true } : f
            )
          );
          await authAxios.post(`/v1/social/acceptFollowRequest/${id}`);
          return;
        }

        const newFollowing = {
          following_id: id,
          following_first_name: first_name,
          following_last_name: last_name,
          following_username: username,
          following_image: avatar,
          is_approved: !isPrivateUser,
        };

        if (mode === "recommendations") {
          setRecommendations((prev) => prev.filter((rec) => rec.id !== id));
        }

        setAuthFollowing((prev) => [...prev, newFollowing]);
        await authAxios.post(`/v1/social/followUser/${id}`);
      } catch (error) {
        console.error("Failed to update follow status:", error);
        if (mode === "following") {
          getFollowing();
        } else if (mode === "followers") {
          getFollowers();
        } else if (mode === "recommendations") {
          getRecommendations();
        }
      }
    };

    const handleRemoveSuggestion = async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUserToRemove(user);
      setShowConfirmDialog(true);
    };

    const handleMenuClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setSelectedUser(user);
      if (isMobile) {
        setShowBottomSheet(true);
      } else {
        setShowPopover(true);
      }
    };

    const cardContent = (
      <div className="tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-border-b tw-border-gray-200 md:hover:tw-bg-gray-50 tw-transition-colors tw-relative tw-rounded-tl-lg tw-rounded-tr-lg">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
          <div className="tw-flex tw-items-center tw-gap-3">
            {imageError ? (
              <div className="tw-w-12 tw-h-12 tw-flex-none tw-rounded-full tw-bg-gradient-to-br tw-from-gray-100 tw-to-gray-200 tw-flex tw-items-center tw-justify-center">
                <span className="tw-text-gray-400 tw-font-medium tw-text-lg">
                  {name.charAt(0)}
                </span>
              </div>
            ) : (
              <img
                src={avatar}
                alt={name}
                className="tw-w-12 tw-h-12 tw-rounded-full tw-object-cover tw-flex-none"
              />
            )}
            <div className="tw-grow">
              <h3 className="tw-font-semibold tw-line-clamp-1 tw-text-gray-900">
                {name}
              </h3>
              <p className="tw-text-gray-500 tw-text-sm tw-line-clamp-1">
                {username}
              </p>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <button
              className={`tw-px-4 tw-py-2 tw-rounded-full tw-font-medium tw-whitespace-nowrap tw-transition-all ${getButtonStyle()}`}
              onMouseEnter={() => !isMobile && setIsHovered(true)}
              onMouseLeave={() => !isMobile && setIsHovered(false)}
              onClick={handleClick}
            >
              {getButtonText()}
            </button>
            {!isOtherUserProfile && mode === "followers" && (
              <div className="tw-relative">
                <button
                  ref={menuButtonRef}
                  onClick={handleMenuClick}
                  className="tw-p-2 tw-rounded-full md:hover:tw-bg-gray-100 tw-transition-colors"
                  aria-label="Menu"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="tw-h-5 tw-w-5 tw-text-gray-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </button>
                {selectedUser && selectedUser.id === user.id && !isMobile && (
                  <DesktopPopover
                    user={selectedUser}
                    onClose={() => {
                      setShowPopover(false);
                      setSelectedUser(null);
                    }}
                  />
                )}
              </div>
            )}
            {mode === "recommendations" && (
              <button
                onClick={handleRemoveSuggestion}
                className="tw-p-2 tw-rounded-full md:hover:tw-bg-gray-100 tw-transition-colors"
                title="Remove suggestion"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-5 tw-w-5 tw-text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* Mutual Connections Section - Only show in recommendations mode */}
        {mode === "recommendations" && (
          <>
            {recommendation_type === 1 &&
              mutual_connections &&
              mutual_connections.length > 0 && (
                <div className="tw-mt-2 tw-flex tw-items-center tw-gap-2">
                  <div className="tw-flex -tw-space-x-2">
                    {mutual_connections.slice(0, 3).map((connection) => (
                      <img
                        key={connection.id}
                        src={connection.image}
                        alt={`${connection.first_name} ${connection.last_name}`}
                        className="tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-white tw-object-cover"
                        title={`${connection.first_name} ${connection.last_name}`}
                      />
                    ))}
                  </div>
                  <span className="tw-text-sm tw-text-gray-500">
                    {mutual_connections.length === 1
                      ? `Followed by ${mutual_connections[0].first_name}`
                      : `Followed by ${mutual_connections[0].first_name} & ${
                          mutual_connections.length - 1
                        } ${
                          mutual_connections.length - 1 === 1
                            ? "other"
                            : "others"
                        }`}
                  </span>
                </div>
              )}
            {recommendation_type === "1.5" && (
              <div className="tw-mt-2 tw-flex tw-items-center tw-gap-2">
                <span className="tw-text-sm tw-text-gray-500">
                  Was on the same trip as you!
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );

    return (
      <>
        {isMobile ? (
          <div onClick={() => setShowUserProfile(true)}>{cardContent}</div>
        ) : (
          <Link to={`/profile/${id}`} className="tw-cursor-pointer">
            {cardContent}
          </Link>
        )}

        <UserProfileBottomSheet
          showUserProfile={showUserProfile}
          setShowUserProfile={setShowUserProfile}
          userId={id}
        />
      </>
    );
  };

  const SkeletonCard = () => (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-border-b tw-border-gray-200">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className="tw-w-12 tw-h-12 tw-rounded-full tw-bg-gray-200 tw-animate-pulse" />
          <div>
            <div className="tw-h-4 tw-w-32 tw-bg-gray-200 tw-rounded tw-animate-pulse" />
            <div className="tw-h-3 tw-w-24 tw-bg-gray-200 tw-rounded tw-mt-2 tw-animate-pulse" />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-2">
          <div className="tw-w-24 tw-h-8 tw-bg-gray-200 tw-rounded-full tw-animate-pulse" />
          <div className="tw-w-8 tw-h-8 tw-bg-gray-200 tw-rounded-full tw-animate-pulse" />
        </div>
      </div>
    </div>
  );

  const [following, setFollowing] = useState([]);
  const [authFollowing, setAuthFollowing] = useState([]);

  const [followers, setFollowers] = useState([]);
  const [authFollowers, setAuthFollowers] = useState([]);

  const [loadingFollowers, setLoadingFollowers] = useState(false);
  const [loadingFollowing, setLoadingFollowing] = useState(false);

  const shownFollowing = following.filter((f) => f.is_approved);
  const followersCount = followers.filter((f) => f.is_approved).length;

  const followersHash = authFollowers.reduce((acc, user) => {
    acc[user.follower_id] = {
      is_private: user.follower_is_private,
      hide_activity: user.hide_activity,
      is_approved: user.is_approved,
    };
    return acc;
  }, {});

  const followingHash = authFollowing.reduce((acc, user) => {
    acc[user.following_id] = {
      is_approved: user.is_approved,
    };
    return acc;
  }, {});

  async function getFollowing() {
    setLoadingFollowing(true);

    const { data: following } = await instance.get("/v1/social/getFollowing", {
      params: {
        userID: user_id,
      },
    });
    const { data: authFollowing } = await instance.get(
      "/v1/social/getFollowing",
      {
        params: {
          userID: currentUserId,
        },
      }
    );

    setFollowing(following);
    setAuthFollowing(authFollowing);
    setLoadingFollowing(false);
  }

  async function getFollowers() {
    setLoadingFollowers(true);
    const { data: followers } = await instance.get("/v1/social/getFollowers", {
      params: {
        userID: user_id,
      },
    });
    const { data: authFollowers } = await instance.get(
      "/v1/social/getFollowers",
      {
        params: {
          userID: currentUserId,
        },
      }
    );
    setFollowers(followers);
    setAuthFollowers(authFollowers);
    setLoadingFollowers(false);
  }

  async function getRecommendations() {
    setLoadingRecommendations(true);

    try {
      const { data } = await authAxios.get(
        "/v1/social/getConnectionRecommendations"
      );

      setRecommendations(data);
    } catch (error) {
      console.error("Failed to fetch recommendations:", error);
    }
    setLoadingRecommendations(false);
  }

  async function searchUsers(searchTerm) {
    setLoadingSearch(true);
    try {
      const { data } = await instance.get(
        `/v1/social/searchSocials?search=${searchTerm}`
      );
      setSearchResults(data);
    } catch (error) {
      console.error("Failed to search users:", error);
    }
    setLoadingSearch(false);
  }

  useEffect(() => {
    getFollowing();
    getFollowers();
    getRecommendations();
  }, []);

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      if (value) {
        searchUsers(value);
        setDebouncedSearchQuery(value);
      } else {
        setSearchResults([]);
        setDebouncedSearchQuery("");
      }
    }, 500),
    []
  );

  const loading =
    loadingFollowers ||
    loadingFollowing ||
    loadingRecommendations ||
    loadingSearch;

  const isInApp = window.isInApp;

  const handleAppNavigation = () => {
    // Check if the WebView bridge is available
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("connectionNavigationBack");
    } else {
      console.error("ReactNativeWebView bridge is not available");
    }
  };

  return (
    <div
      className={`tw-max-w-5xl tw-mx-auto md:tw-mt-[86px] ${
        isInApp ? "tw-py-0" : "tw-py-8"
      }`}
    >
      {/* Header Section */}

      <div className="tw-px-4">
        <div className="tw-flex tw-gap-4 tw-mb-4">
          {isMobile && (
            <button
              onClick={() => {
                if (isInApp) {
                  handleAppNavigation();
                } else {
                  navigate(-1);
                }
              }}
              className="tw-flex tw-items-center tw-justify-center tw-w-14 tw-h-14 tw-rounded-full tw-transition-all tw-duration-200 tw-ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-7 tw-w-7 tw-text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          <div>
            <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-gray-900">
              Connections
            </h1>
            <p className="tw-mt-1.5 tw-text-gray-600 tw-text-sm md:tw-text-base">
              Manage your network of followers and people you follow
            </p>
          </div>
        </div>
      </div>

      {/* Search Input */}
      <div className="tw-px-4 tw-mb-6 tw-flex tw-items-center tw-gap-4">
        <div className="tw-relative tw-grow">
          <input
            type="text"
            placeholder="Search all users"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-full tw-bg-gray-50 focus:tw-bg-white focus:tw-border-[deepskyblue] focus:tw-ring-1 focus:tw-ring-[deepskyblue] tw-transition-colors"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-text-gray-400 tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {isMobile && isInApp && !isOtherUserProfile && (
          <button
            onClick={handleImportContacts}
            className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-[deepskyblue] tw-text-white tw-rounded-full hover:tw-bg-[#00b2ff] tw-transition-colors"
            title="Import Contacts"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-book-user"
            >
              <path d="M15 13a3 3 0 1 0-6 0" />
              <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
              <circle cx="12" cy="8" r="2" />
            </svg>
          </button>
        )}
      </div>

      {/* Show tabs only when not searching */}
      {!debouncedSearchQuery && (
        <div className="tw-border-b tw-border-gray-200 tw-mb-6 tw-sticky tw-top-0 tw-pt-4 lg:tw-top-[86px] tw-bg-white tw-z-10">
          <nav className="tw-flex tw-space-x-8 tw-px-4">
            <button
              onClick={() => {
                setActiveTab("following");
                getFollowing();
              }}
              className={`tw-pb-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm md:tw-text-base tw-transition-colors tw-whitespace-nowrap ${
                activeTab === "following"
                  ? "tw-border-[deepskyblue] tw-text-[deepskyblue]"
                  : "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300"
              }`}
            >
              Following ({shownFollowing.length})
            </button>
            <button
              onClick={() => {
                setActiveTab("followers");
                getFollowers();
              }}
              className={`tw-pb-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm md:tw-text-base tw-transition-colors tw-whitespace-nowrap ${
                activeTab === "followers"
                  ? "tw-border-[deepskyblue] tw-text-[deepskyblue]"
                  : "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300"
              }`}
            >
              Followers {!!followersCount && <>({followersCount})</>}
            </button>
            <button
              onClick={() => {
                setActiveTab("recommendations");
                getRecommendations();
              }}
              className={`tw-pb-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm md:tw-text-base tw-transition-colors tw-whitespace-nowrap ${
                activeTab === "recommendations"
                  ? "tw-border-[deepskyblue] tw-text-[deepskyblue]"
                  : "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300"
              }`}
            >
              Suggested
            </button>
          </nav>
        </div>
      )}

      {/* Tab Content */}
      <div key={debouncedSearchQuery ? "search" : activeTab}>
        {loading ? (
          <div className="tw-grid tw-gap-4 md:tw-gap-6">
            {Array(3)
              .fill()
              .map((_, i) => (
                <SkeletonCard key={i} />
              ))}
          </div>
        ) : debouncedSearchQuery ? (
          // Show search results when searching
          searchResults.length > 0 ? (
            <Virtuoso
              useWindowScroll
              totalCount={searchResults.length}
              itemContent={(index) => (
                <div className="tw-px-4">
                  <UserCard
                    key={searchResults[index].id}
                    avatar={searchResults[index].image}
                    first_name={searchResults[index].first_name}
                    last_name={searchResults[index].last_name}
                    tele_username={searchResults[index].tele_username}
                    username={searchResults[index].username}
                    id={searchResults[index].id}
                    followingHash={followingHash[searchResults[index].id]}
                    followersHash={followersHash[searchResults[index].id]}
                    mode={"search"}
                  />
                  <div className="tw-h-4" />
                </div>
              )}
            />
          ) : (
            <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
              <p className="tw-text-gray-500 tw-text-lg">
                No users found matching your search
              </p>
            </div>
          )
        ) : activeTab === "following" ? (
          shownFollowing.length > 0 ? (
            <Virtuoso
              useWindowScroll
              totalCount={shownFollowing.length}
              itemContent={(index) => (
                <div className="tw-px-4">
                  <UserCard
                    key={shownFollowing[index].id}
                    created_at={shownFollowing[index].created_at}
                    avatar={shownFollowing[index].following_image}
                    first_name={shownFollowing[index].following_first_name}
                    last_name={shownFollowing[index].following_last_name}
                    tele_username={
                      shownFollowing[index].following_tele_username
                    }
                    username={shownFollowing[index].following_username}
                    id={shownFollowing[index].following_id}
                    mode="following"
                    followingHash={
                      followingHash[shownFollowing[index].following_id]
                    }
                    followersHash={
                      followersHash[shownFollowing[index].following_id]
                    }
                  />
                  <div className="tw-h-4" />
                </div>
              )}
            />
          ) : (
            <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
              <p className="tw-text-gray-500 tw-text-lg">
                You are not following anyone yet
              </p>
              <button
                onClick={() => setActiveTab("recommendations")}
                className="tw-mt-4 tw-text-[deepskyblue] tw-font-medium hover:tw-underline"
              >
                Discover people to follow
              </button>
            </div>
          )
        ) : activeTab === "followers" ? (
          followers.length > 0 ? (
            <Virtuoso
              useWindowScroll
              totalCount={followers.length}
              itemContent={(index) => (
                <div className="tw-px-4">
                  <UserCard
                    key={followers[index].id}
                    created_at={followers[index].created_at}
                    avatar={followers[index].follower_image}
                    first_name={followers[index].follower_first_name}
                    last_name={followers[index].follower_last_name}
                    tele_username={followers[index].follower_tele_username}
                    username={followers[index].follower_username}
                    id={followers[index].follower_id}
                    followingHash={followingHash[followers[index].follower_id]}
                    followersHash={followersHash[followers[index].follower_id]}
                    mode="followers"
                  />
                  <div className="tw-h-4" />
                </div>
              )}
            />
          ) : (
            <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
              <p className="tw-text-gray-500 tw-text-lg">
                You don't have any followers yet
              </p>
              <button className="tw-mt-4 tw-text-[deepskyblue] tw-font-medium hover:tw-underline">
                Share your profile to get followers
              </button>
            </div>
          )
        ) : recommendations.length > 0 ? (
          <Virtuoso
            useWindowScroll
            totalCount={recommendations.length}
            itemContent={(index) => (
              <div className="tw-px-4">
                <UserCard
                  key={recommendations[index].id}
                  avatar={recommendations[index].image}
                  first_name={recommendations[index].first_name}
                  last_name={recommendations[index].last_name}
                  username={recommendations[index].username}
                  id={recommendations[index].id}
                  mode="recommendations"
                  mutual_connections={recommendations[index].mutual_connections}
                  recommendation_type={
                    recommendations[index].recommendation_type
                  }
                />
                <div className="tw-h-4" />
              </div>
            )}
          />
        ) : (
          <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
            <p className="tw-text-gray-500 tw-text-lg">
              No recommendations available at the moment
            </p>
          </div>
        )}
      </div>

      {selectedUser && isMobile ? (
        <MobileBottomSheet
          user={selectedUser}
          onClose={() => {
            setShowBottomSheet(false);
            setSelectedUser(null);
          }}
        />
      ) : null}

      {/* Confirmation Dialog */}
      {createPortal(
        <AnimatePresence>
          {showConfirmDialog && (
            <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-[501]">
              <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-max-w-sm tw-mx-4">
                <h3 className="tw-text-lg tw-font-semibold tw-mb-2">
                  Remove Suggestion
                </h3>
                <p className="tw-text-gray-600 tw-mb-4">
                  Are you sure you want to remove {userToRemove.first_name} from
                  your recommendations? You won't see them suggested again.
                </p>
                <div className="tw-flex tw-justify-end tw-gap-3">
                  <button
                    onClick={() => setShowConfirmDialog(false)}
                    className="tw-px-4 tw-py-2 tw-rounded-lg tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmRemoveSuggestion}
                    className="tw-px-4 tw-py-2 tw-rounded-lg tw-bg-red-500 tw-text-white hover:tw-bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          )}
        </AnimatePresence>,
        document.body
      )}

      {/* Success/Error Toast */}
      {createPortal(
        <AnimatePresence>
          {toastMessage && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className={`tw-fixed tw-bottom-4 tw-left-1/2 -tw-translate-x-1/2 tw-px-6 tw-py-3 tw-rounded-lg tw-shadow-lg tw-flex tw-items-center tw-gap-2 tw-z-[502] ${
                toastType === "success" ? "tw-bg-green-500" : "tw-bg-red-500"
              }`}
            >
              {toastType === "success" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-5 tw-w-5 tw-text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-5 tw-w-5 tw-text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              <span className="tw-text-white tw-font-medium">
                {toastMessage}
              </span>
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </div>
  );
};

export default Connections;
