import React, { useState, useEffect } from "react";
import "./header.scss";
import { MenuButton } from "./MenuButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalStates";
import { useWindowSize } from "../../customHooks/useWindowSize";
import { isMobile } from "react-device-detect";

import Button from "@mui/material/Button";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const MobileMenuHeader = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  if (isMobile) {
    return (
      <div className="mobileHeaderContainer">
        <button className="mobileHeaderLeft">
          <img
            src="/new_logo/logo_only/logo192.png"
            className="mobileHeaderLeftLogo"
            onClick={() => {
              navigate("/all-trips");
            }}
          />
        </button>

        <div className="mobileHeaderRight">
          <MenuButton
            handleClick={handleClick}
            handleClose={handleClose}
            anchorEl={anchorEl}
            handleProfileClick={handleProfileClick}
            open={open}
          />
        </div>
      </div>
    );
  }
};
