import { useMediaQuery } from "react-responsive";

export const useTabletOrMobile = () => {
  return useMediaQuery({ query: "(max-width: 1224px)" });
};

export const useIsDesktop = () => {
  return useMediaQuery({ query: "(min-width: 1224px)" });
};

export const useIsMobile = () => {
  return useMediaQuery({ query: "(max-width: 639px)" });
};
