import { useState } from "react";
import Calendar from "../components/calendar/calendar";

export default function TestCalendar() {
  const [range, setRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [showCalendar, setShowCalendar] = useState(false);

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className="tw-h-screen tw-w-screen tw-flex tw-items-center tw-justify-center">
      <div className="tw-relative">
        <button
          onClick={() => setShowCalendar(!showCalendar)}
          className="tw-px-4 tw-py-2 tw-bg-[deepskyblue] tw-text-white tw-rounded-lg tw-shadow hover:tw-bg-[#00a0e6] tw-transition-colors"
        >
          {range?.from
            ? range.to
              ? `${formatDate(range.from)} - ${formatDate(range.to)}`
              : formatDate(range.from)
            : "Select Dates"}
        </button>
      </div>
      <Calendar
        range={range}
        setRange={setRange}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
      />
    </div>
  );
}
