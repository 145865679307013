import { motion } from "framer-motion";
import { Link, Navigate } from "react-router-dom";
import authAxios from "../authAxios";
import { useGlobalState } from "../GlobalStates";
import { useTabletOrMobile } from "../hooks/responsive";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { useState } from "react";

const Privacy = () => {
  const [user, setUser] = useGlobalState("user");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleToggle = async (setting) => {
    if (setting === "privateAccount") {
      setUser((prev) => ({
        ...prev,
        is_private: !prev.is_private,
      }));

      try {
        await authAxios.post("/v1/social/updatePrivateAccount", {
          privateAccount: !user.is_private,
        });
        setSnackbar({
          open: true,
          message: "Private account setting updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating private account:", error);
        // Revert on error
        setUser((prev) => ({
          ...prev,
          is_private: !prev.is_private,
        }));
        setSnackbar({
          open: true,
          message: "Failed to update private account setting",
          severity: "error",
        });
      }
    } else if (setting === "hideActivity") {
      setUser((prev) => ({
        ...prev,
        hide_social_activity: !prev.hide_social_activity,
      }));

      try {
        await authAxios.post("/v1/social/updateHideActivity", {
          hideActivity: !user.hide_social_activity,
        });
        setSnackbar({
          open: true,
          message: "Activity visibility updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating hide activity:", error);
        // Revert on error
        setUser((prev) => ({
          ...prev,
          hide_social_activity: !prev.hide_social_activity,
        }));
        setSnackbar({
          open: true,
          message: "Failed to update activity visibility",
          severity: "error",
        });
      }
    } else if (setting === "pushNotifications") {
      setUser((prev) => ({
        ...prev,
        allow_social_push_notifications: !prev.allow_social_push_notifications,
      }));

      try {
        await authAxios.post("/v1/social/updatePushNotifications", {
          allowPushNotifications: !user.allow_social_push_notifications,
        });
        setSnackbar({
          open: true,
          message: "Push notification settings updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating push notifications:", error);
        // Revert on error
        setUser((prev) => ({
          ...prev,
          allow_social_push_notifications:
            !prev.allow_social_push_notifications,
        }));
        setSnackbar({
          open: true,
          message: "Failed to update push notification settings",
          severity: "error",
        });
      }
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const isMobile = useTabletOrMobile();

  const [userLoading] = useGlobalState("userLoading");

  if (userLoading) {
    return <CircularProgress />;
  }

  if (!user.id) {
    return <Navigate to="/" />;
  }

  return (
    <div className="tw-max-w-5xl tw-mx-auto tw-min-h-screen tw-bg-white tw-p-4 md:tw-p-8 md:tw-mt-[86px]">
      {isMobile && (
        <Link to={`/profile`}>
          <button className="tw-flex tw-items-center tw-gap-2 tw-mb-6 tw-text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-5 tw-w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            Back
          </button>
        </Link>
      )}

      <motion.div
        className="tw-max-w-3xl tw-mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-gray-900 tw-mb-2">
          Privacy Settings
        </h1>
        <p className="tw-text-gray-600 tw-mb-8">
          Manage your privacy preferences and control how others interact with
          your account.
        </p>

        {/* Account Privacy Section */}
        <motion.section
          className="tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-mb-6"
          variants={itemVariants}
        >
          <h2 className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-800">
            Account Privacy
          </h2>
          <div className="tw-space-y-4">
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
              <div className="tw-flex-1">
                <h3 className="tw-font-medium">Private Account</h3>
                <p className="tw-text-sm tw-text-gray-500">
                  Only approved followers can see your content
                </p>
              </div>
              <button
                onClick={() => handleToggle("privateAccount")}
                className={`tw-w-12 tw-h-6 tw-rounded-full tw-p-1 tw-transition-colors tw-duration-300 ${
                  user.is_private ? "tw-bg-[deepskyblue]" : "tw-bg-gray-300"
                }`}
              >
                <div
                  className={`tw-w-4 tw-h-4 tw-rounded-full tw-bg-white tw-transform tw-transition-transform tw-duration-300 ${
                    user.is_private ? "tw-translate-x-6" : "tw-translate-x-0"
                  }`}
                />
              </button>
            </div>

            <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
              <div className="tw-flex-1">
                <h3 className="tw-font-medium">Hide Activity</h3>
                <p className="tw-text-sm tw-text-gray-500">
                  Hide your activity from your followers
                </p>
              </div>
              <button
                onClick={() => handleToggle("hideActivity")}
                className={`tw-w-12 tw-h-6 tw-rounded-full tw-p-1 tw-transition-colors tw-duration-300 ${
                  user.hide_social_activity
                    ? "tw-bg-[deepskyblue]"
                    : "tw-bg-gray-300"
                }`}
              >
                <div
                  className={`tw-w-4 tw-h-4 tw-rounded-full tw-bg-white tw-transform tw-transition-transform tw-duration-300 ${
                    user.hide_social_activity
                      ? "tw-translate-x-6"
                      : "tw-translate-x-0"
                  }`}
                />
              </button>
            </div>
          </div>
        </motion.section>

        {/* Notifications Section */}
        <motion.section
          className="tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4"
          variants={itemVariants}
        >
          <h2 className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-800">
            Notifications
          </h2>
          <div className="tw-space-y-4">
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
              <div className="tw-flex-1">
                <h3 className="tw-font-medium">Push Notifications</h3>
                <p className="tw-text-sm tw-text-gray-500">
                  Receive push notifications for new updates to your social
                  network
                </p>
              </div>
              <button
                onClick={() => handleToggle("pushNotifications")}
                className={`tw-w-12 tw-h-6 tw-rounded-full tw-p-1 tw-transition-colors tw-duration-300 ${
                  user.allow_social_push_notifications
                    ? "tw-bg-[deepskyblue]"
                    : "tw-bg-gray-300"
                }`}
              >
                <div
                  className={`tw-w-4 tw-h-4 tw-rounded-full tw-bg-white tw-transform tw-transition-transform tw-duration-300 ${
                    user.allow_social_push_notifications
                      ? "tw-translate-x-6"
                      : "tw-translate-x-0"
                  }`}
                />
              </button>
            </div>

            <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-t tw-pt-4">
              <div className="tw-flex-1">
                <h3 className="tw-font-medium">
                  Advanced Notification Settings
                </h3>
                <p className="tw-text-sm tw-text-gray-500">
                  Manage your notification preferences
                </p>
              </div>
              <Link
                to="/notif-edit"
                className="tw-px-4 tw-py-2 tw-rounded-lg tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200 tw-transition-colors tw-duration-200 tw-flex tw-items-center tw-gap-2"
              >
                Configure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </motion.section>
      </motion.div>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Privacy;
