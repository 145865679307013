import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { hashUrl } from "../../utils/file";
import pLimit from "p-limit";
import Skeleton from "@mui/material/Skeleton";

const limit = pLimit(1);

export const ImageResizable = ({
  size = "m",
  fadeIn = false,
  src,
  asChild,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(
    "https://d1puf2nnbcyl9q.cloudfront.net/image_2024-10-09_18-02-12.png"
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (src) {
      const hashedFileName = `${hashUrl(src)}-${size}`;
      const hashedUrl = `https://d1puf2nnbcyl9q.cloudfront.net/${hashedFileName}`;

      setImageSrc(hashedUrl);
      setIsLoading(false);
    }
  }, [src, size]);

  const handleImageError = async () => {
    setImageSrc(src);
    setIsLoading(false);

    limit(async () => {
      try {
        const response = await axios.post("/v1/upload/imageCompressionRemote", {
          url: src,
        });

        if (response.data && response.data.compressedImage) {
          const compressedUrlForSize = response.data.compressedImage[size];
          if (compressedUrlForSize) {
            setImageSrc(compressedUrlForSize);
          }
        }
      } catch (error) {}
    });
  };

  const Content = (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
        />
      )}
      <img
        src={imageSrc}
        onError={handleImageError}
        data-original-src={src}
        loading="lazy"
        {...props}
        style={{
          opacity: fadeIn || isLoading ? 0 : 1,
          transition: fadeIn ? "opacity 0.3s ease-in-out" : undefined,
          ...props.style,
        }}
        onLoad={(e) => {
          setIsLoading(false);
          if (fadeIn) {
            e.target.style.opacity = 1;
          }
          if (props.onLoad) props.onLoad(e);
        }}
      />
    </>
  );

  if (asChild) {
    return Content;
  }

  return <>{Content}</>;
};
